.container {
    width: 20vw;
    height: calc(100vh - (66px + 34px + 34px));
    border-right: 1px solid #d6d6d6;
}

.header {
    height: 66px;
    /* height: 70px; */
    background: #FAFAFA;
    width: 100%;
    padding: 13px 1rem 10px 1rem;
}

.search {
    margin-top: 4px;
    margin-bottom: 4px;
}

.body {
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    padding: 10px 1rem 10px 1rem;
}

.body::-webkit-scrollbar {
    width: 7px;
}

/* Handle */
.body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.body::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}
.container {
    height: 52px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 6px;
    padding-right: 6px;
    width: 95%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.container:hover {
    background-color: #fafafa;
    border-radius: 5px;
}

.content {
    display: flex;
    align-items: center;
}

.iconBox {
    margin-right: 15px;
}

.iconBox > svg {
    color: #7A7A7A;
}

.breadcrumb {
    position: absolute;
    bottom: 2px;
    left: 8px;
}


@media only screen and (max-width: 720px) {
    .container {
        height: 60px;
    }
}
/* .header {
    height: 100%;
} */

/* .filter {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1.7rem;
} */

.container {
    display: flex;
    align-items: flex-start;
    padding-top: 1rem;
    padding-left: 2.3rem;
    padding-right: 2.3rem;
    width: 100%;
    height: 100%;
}

.containerSidebarOpen {
    margin-left: 240px;
    width: calc(100% - (240px / var(--viewport-width)));
    padding-left: 0.5rem;
    padding-right: 0rem;
}

.body {
    /* display: flex;
    align-items: flex-start; */
    padding-left: 1.5rem;
    /* width: 100vw; */
    width: 80vw;
}

.bodySidebarOpen {
    width: 65vw;
    padding-left: 0.6rem;
}

.invalidIntro {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.bodyRealtimeMobile {

}

.bodyRecapMonthWeeklyMobile {

}

@media only screen and (max-width: 1024px) {
    .container {
        padding-bottom: 1rem;
        position: relative;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        padding-bottom: 1rem;
        height: 100%;
    }

    .body {
        width: 91vw;
        padding-left: 0px;
    }

    .bodyRealtimeMobile {
        /* height: 190vh; */
    }
    
    .bodyRecapMonthWeeklyMobile {
        /* height: 293vh; */
    }
}
.label {
  display: inline-block;
  padding: 5px;
  min-height: 20px;
  min-width: 32px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.label__block {
  min-height: 30px;
  min-width: 42px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.label:hover,.label__block:hover {
  opacity: 0.8;
}

.label p {
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  margin: 0;
}

.label__block > p {
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  margin: 0;
}

.wrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

.image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.medium {
  height: 50px;
  width: 50px;
}

.small {
  height: 23px;
  width: 23px;
}

.colorImageGray {
  background-color: rgb(181,181,181);
  top: 1.5px;
  position: absolute;
  z-index: 1;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  cursor: pointer;
}

.icon {
  cursor: pointer;
}

/* Add comment section */

.AddCommentContainer {
  display: flex;
  margin-bottom: 20px;
  background-color: #e3e3e3;
  border-radius: 5px;
  padding: 5px;
}

.inputComment {
  width: 100%;
}

.formInitial {
  cursor: pointer;
}

.actionSection {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
}

.actionSection svg {
  margin-left: 7px;
  cursor: pointer;
  width: 20px;
}

.addListButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addListButton svg {
  margin-right: 3px;
}

.buttonBottom {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.buttonBottom svg {
  margin-right: 3px;
  width: 20px;
}

.buttonBottom a {
  font-size: 0.8rem;
}
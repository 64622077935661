.container {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    padding-right: 5px;
    padding-left: 2px;
    padding-top: 1px;
    padding-bottom: 0;
    border-radius: 20px;
    margin: 6px 6px 6px 0px;
    cursor: pointer;
    border: 1px solid #F0F1F7;
    height: 34px;
}

.content {
    flex-wrap: wrap;
}

.content > p {
    font-size: 0.8rem;
    margin-bottom: 0;
}

.deleteIcon > svg {
    color: #FF7171;
    width: 20px !important;
    margin-left: 5px;
}

.deleteIcon:hover {
    opacity: 0.8;
}
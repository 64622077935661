.container {
  padding-left: 12vw;
  padding-right: 12vw;
  /* margin-top: 15px; */
  padding-top: 3vh;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.searchAndSortSection {
  width: 20%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.search {
  margin-right: 0.3rem;
}

.sidebarOpen {
  margin-left: 240px;
  padding-left: 6vw;
  padding-right: 6vw;
}

.DashboardPage__mainRow {
  display: flex;
  margin-bottom: 5%;
}

.DashboardPage__leftCol {
  order: 1;
}

.DashboardPage__mainCol {
  order: 2;
  padding-left: 30px;
}

.DashboardPage__header {
  /* margin-left: 6px;
  margin-right: 13px; */
  margin-bottom: 10px;
  margin-top: 10px;
  display: block;
}

.DashboardPage__title {
  
  display: flex;
  align-items: center;
}

.DashboardPage__title > h1 {
  margin-bottom: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #B5B5B5;
}

.DashboardPage__image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.DashboardPage__icon {
  margin-right: 8px;
  color: #B5B5B5;
  width: 20px;
}


.DashboardPage__flex {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  min-height: 100px;
  margin-bottom: 30px;
  margin-left: -10px;
}

.DashboardPage__flex_allignCenter {
  align-items: center;
  justify-content: center;
}

.companyLogo {
  max-width: 150px;
  max-height: 100px;
  margin-right: 20px;
}

.DashboardPage__relative {
  position: relative;
}



.leftCol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}

.leftCol__smallTitle {
  color: #263238;
  font-size: 0.8rem;
  margin: 0;
}

.leftCol__addCompany {
  cursor: pointer;
}

.welcomeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
}

.welcomeSection img {
  width: 20vw;
  margin-bottom: 10px;
}

.welcomeSection h1 {
  font-size: 3rem;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1200px) {
  .sidebarOpen {
    margin-left: 50px;
  }
  .searchAndSortSection {
    width: 50%;
  }
}

@media only screen and (max-width: 720px) {
  .welcomeSection {
    padding-right: 15%;
    padding-left: 15%;
  }
  .welcomeSection img {
    width: 50vw;
    
  }
  
  .welcomeSection h1 {
    font-size: 2rem;
  }

  .container {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .sidebarOpen {
    margin-left: 50px;
  }
  .searchAndSortSection {
    width: 80%;
  }
}
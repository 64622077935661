.container {
    display: flex;
    align-items: center;
}

.text {
    margin-right: 0.5rem;
}

.button {
    border-radius: 100%;
    border: 1px solid #B5B5B5;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.button > svg {
    width: 18px;
    color: #42E691;
}

.button:hover {
    opacity: 0.8;
}

.LoginButton {
    background: #FFFFFF;
    border: 2px solid #1F3762;
    box-sizing: border-box;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
}

.LoginButton:hover {
    background: #f9f9f9;
}

.LoginButton > p {
    margin: 0;
    margin-left: 8px; 
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #081B4B;
}

.LoginButton > img {
    width: 25px;
}
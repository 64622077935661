.container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* .box {
    width: 100px;
    height: 50px;
    background-color: beige;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

.content {
    /* width: 120px; */
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1;
    padding: 0.3rem;
}

.arrowIllustration {
    position: absolute;
    left: -40px;
    top: -30px;
}

.arrowIllustration > img {
    width: 190px;
}


.churnIllustration {
    position: absolute;
    right: -100px;
    top: 0px;
}

.churnIllustration > img {
    max-height: 90px;
}
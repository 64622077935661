.container {
    width: 28px;
    height: 28px;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
}

.container > svg {
    width: 20px;
    color: #b5b5b5;
}

.container:hover {
    opacity: 0.8;
}
.container {
    box-sizing: border-box;
    width: 240px;
    height: 365px;
    border-radius: 15px;
    padding: 1rem;
    position: relative;
}

.starter {
    border: 2px solid #DEEAFF;
    background: url('../../../../assets/BillingPage/starter-vector.png'), #ffffff;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 130px;
    background-size: 50%;
}

.growth {
    border: 2px solid #DEEAFF;
    background: url('../../../../assets/BillingPage/starter-vector.png'), #ffffff;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 130px;
    background-size: 50%;
}

.pro {
    /* border: 2px solid #BBEE7B;
    background: url('../../../../assets/BillingPage/pro-vector.png'), #ffffff;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 130px;
    background-size: 50%; */
    border: 2px solid #DEEAFF;
    background: url('../../../../assets/BillingPage/starter-vector.png'), #ffffff;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 130px;
    background-size: 50%;
}

.scale {
    border: 2px solid #BBEE7B;
    background: url('../../../../assets/BillingPage/pro-vector.png'), #ffffff;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 130px;
    background-size: 50%;
}

.premium {
    border: 2px solid #FDC532;
    background: url('../../../../assets/BillingPage/premium-vector.png'), #ffffff;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-size: 60%;
    /* width: 10%; */
}

.title {
    margin-top: 0.5rem;
    margin-bottom: 0.8rem;
}

.price {
    margin-bottom: 1.3rem;
   display: flex;
   align-items: center;
}

.price__saving {
    margin-left: 5px;
}

.feature {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.icon {
    margin-right: 1.2rem;
}

.icon > svg {
    width: 20px;
    color: #FDC532;
}

.button {
    position: absolute;
    bottom: 10px;
    left: 12px;
    width: 90%;
}

.features {
    position: absolute;
    left: 12px;
    bottom: 60px;
}

.priceTotal {
    display: flex;
    align-items: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
}
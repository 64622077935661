.menu {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 3px;
    cursor: pointer;
    height: 22px;
  }
  
  .menu:hover {
    background-color: white;
    border-radius: 3px;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .text > svg {
    width: 15px;
  }
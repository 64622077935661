.box {
  position: absolute;
  bottom: -4px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #0278ae;
  border-radius: 100%;
}

.box svg {
  fill: white;
  width: 12px;
  margin: 0;
}

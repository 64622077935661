.container {
  display: flex;
  align-items: center;
  
}

.Members__relative {
  position: relative;
}

p {
  margin-bottom: 5px;
}

.Members__smallTitle {
  color: #263238;
  font-size: 0.7rem;
}

.Members__imageMember {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
}



.plusButton {
  background-color: lightgrey;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plusButton:hover {
  opacity: 0.8;
}

.marginLeft {
  margin-left: 0.2rem;
}
.date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 15px;
}

.commentBox {
    display: flex;
    justify-content: flex-start;
}

.photoAndUser {
    display: flex;
}

.photo {
    margin-right: 5px;
}

.content {
    width: 100%;
}
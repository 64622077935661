.title {
    margin-bottom: 0.5rem;
    position: relative;
}

.button {
    position: relative;
}

.icon {
    position: absolute;
    top: -5px;
    right: 0px;
}

.icon > svg {
    width: 16px;
    color: #FDC532;
}
.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    margin-bottom: 1rem;
}

.text {
    margin-bottom: 3rem;
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;
}

.separator {
    margin-right: 4rem;
    margin-left: 4rem;
}


@media only screen and (max-width: 1024px) {
    .separator {
        margin-right: 3rem;
        margin-left: 3rem;
    }
  }
  
  @media only screen and (max-width: 720px) {
    .container {
        padding-top: 6rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: auto;
        display: block;
    }
    .action {
        width: auto;
        display: block;
      }

      .text {
        margin-bottom: 1.5rem;
    }

    .actionBox {
        display: flex;
        justify-content: center;
    }

    .separator {
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
  }
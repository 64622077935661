.actionSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.actionSection__edit button {
  margin-right: 5px;
}

.actionSection__delete {
  position: relative;
}

.actionSection__delete svg {
  width: 20px
}


.form {
  margin-bottom: 15px;
}

.formText {
  margin-bottom: 10px;
}
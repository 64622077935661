.inputSection {
  margin-bottom: 15px;
}

.inputSection__name {
  margin-bottom: 15px;
}

.actionSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionSection__edit {
  display: flex;
}

.actionSection__edit button {
  margin-right: 5px;
}

.actionSection__delete {
  position: relative;
}

.actionSection__delete svg {
  width: 20px
}

.container {
    padding: 1rem;
    border-radius: 20px;
    border: 1px solid #D6D6D6;
    background-color: white;
    min-height: 135px;
    width: 100%;
}

.icon {
    margin-right: 0.2rem;
}
.icon > svg {
    width: 20px;
    color: #B5B5B5;
}

.footer {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* margin-top: 0.5rem; */
    flex-wrap: wrap;
}

.desc {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
}

.info {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.button {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
}


@media only screen and (max-width: 1024px) {
    
}

@media only screen and (max-width: 720px) {
    /* .container {
        width: 230px
    } */
}
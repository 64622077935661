.container {
    height: 120px;
    padding: 11px 13px 11px 10px;
    gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 95%;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 0.5rem;
    position: relative;
}

.container:hover {
    background-color: #fafafa;
    border-radius: 5px;
}

.content {
    height: 70px;
}

.iconBox {
    margin-right: 15px;
}

.iconBox > svg {
    color: #7A7A7A;
}

.breadcrumb {
    position: absolute;
    bottom: 2px;
    left: 8px;
}


@media only screen and (max-width: 720px) {
    .container {
        height: 165px;
    }
}
.container {
  padding-top: 3vh;
  transition: margin-left 0.3s ease;
}

.sidebarOpen {
  margin-left: 240px;
}

.role {
  margin-left: 1rem;
  margin-right: 1rem;
}

.archivedSection {
  display: flex;
  background-color: #fafcc2;
  align-items: center;
  padding: 6px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.archivedSection svg {
  margin-right: 6px;
}

.archivedSection h1 {
  font-size: 1rem;
  margin: 0;
}

.rowBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.colBox {
  padding-left: 2px;
  padding-right: 2px;
}

.title__settings {
  margin-right: 14px;
  cursor: pointer;
}

.title__settings:hover {
  opacity: 0.8;
}

.title__settings > svg {
  color: #B5B5B5;
  width: 24px;
}

@media only screen and (max-width: 720px) {
  .rowBox {
    flex-direction: column;
  }

  .title__settings {
    margin-right: 7px;
    cursor: pointer;
  }

  .role {
    margin-left: 10px;
    margin-right: 4px;
}
}
.hidden {
  display: none;
}

.SmallCard {
  background: #fff;
  padding: 5px 7px 5px 7px;
  width: 100%;
  text-align: left;
  position: relative;
}

.borderSemiRounded {
  border-radius: 5px;
}

.borderSquare {
  border-radius: 0px;
}

.shadowDisabled {

}

.shadowRegular {
  box-shadow: 0 1px 1px #ccc;
}

.SmallCard h1 {
  font-size: 0.9rem;
  margin-bottom: 6px;
  position: relative;
}
.SmallCard p {
  font-size: 0.7rem;

  white-space: pre-wrap;
  word-wrap: break-word;
}

.SmallCard button {
  position: relative;
  float: right;
  margin-right: 10px;
  margin-top: 50px;
}

.SmallCard:hover {
  cursor: pointer;
}



.labelsSection {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.imageMemberSection {
  display: flex;
  flex-wrap: wrap;
}

.othersPropertiesSection {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}

.cardMenu {
  display: inline-flex;
  margin-left: auto;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 200;
  padding: 2px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 5px; 
}

.cardMenu > svg {
  color: white;
  width: 0;
}

.SmallCard:hover .cardMenu {
  background-color: lightgray;
}

.SmallCard:hover .cardMenu > svg {
  width: 17px;
  color: white;
}

.SmallCard:hover .cardMenu:hover {
  background-color: grey;
}

.cardBlur::before {
  position: absolute;
  left:0; top:0; bottom:0; right:0;
  z-index: 1;
  background-color: white;
  border-radius: 5px;
  opacity: 0.98;
  content: "";
  /* background: inherit; */
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -ms-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
  width: 100%;
  height: 100%;
}

.cardBlurText {
  position: absolute;
  left:0; top:0; bottom:0; right:0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blurIconPrivate {
  margin-right: 0.5rem;
}

.teamAndList {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.3rem;
}

.dateBox {
  margin-bottom: 0.1rem;
}
.container {
    width: 100%;
    height: 65px;
    background: #ffffff;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 0.5rem 5px 0.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.container:hover {
    background: #FAFAFA;
}

.selected {
    background: #FAFAFA;
}

.photo {
    width: 50px;
    height: 50px;
}

.photo > div {
    margin: 0 !important;
}

.textAndCounter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
}

.text {
    width: 100%;
    height: 100%;
    padding-top: 5px;
}

.name {
    margin-bottom: 2px;
}
.LoadingWithAnimation {
    height: 55vh;
    display: grid;
    place-items: center;
}

.LoadingWithAnimation__container {
    position: relative;
    text-align: center;
    height: 100%;
    width: 100%;
}

.LoadingWithAnimation__container > img {
    width: 300px;
    margin-top: 100px;
}

.LoadingWithAnimation__text {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.LoadingWithAnimation__text > h1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    color: #1F3762;
}

.LoadingWithAnimation__button {
    margin-top: 30px;
}

.LoadingWithAnimation__insideButton {
    display: flex;
    align-items: center;
    margin:auto;
}

.LoadingWithAnimation__insideButton > span {
    margin-left: 5px;
    font-size: 0.9rem;
}

.LoadingWithAnimation__insideButton > svg {
    width: 1rem;
}  
.container {
  padding: 2rem 2.5rem;
}

.headerSection {
  display: flex;
  justify-content: space-between;
}

.titleSection {
  padding-right: 20px;
}

.menuSection {
  position: relative;
}



.titleSection h1 {
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .titleSection h1 {
    font-size: 1.5rem;
  }

  .titleSection p {
    font-size: 0.8rem;
  }
}


.titleSection__creator {
  display: flex;
  margin-top: 10px;
}

.titleSection__creator__photo {
  margin-right: 7px;
}

.titleSection__creator__photo img {
  margin: 0;
}

.titleSection__creator__body h1 {
  font-size: 1.1rem;
  margin: 0;
}

.titleSection svg {
  cursor: pointer;
}

.emojiPicker {
  position: absolute;
  top: 0;
  right: 0;
}

.questionsSection {
  min-height: 54vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 0;
}


.archivedSection {
  display: flex;
  background-color: #fafcc2;
  align-items: center;
  padding: 6px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.archivedSection svg {
  margin-right: 6px;
}

.archivedSection h1 {
  font-size: 1.2rem;
  margin: 0;
}

.commentSection {
  display: block;
  position: relative;
}

.infiniteScroll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}

.typingSection {
  min-height: 24px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 10px;
}
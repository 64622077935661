.container {
  width: 350px;
  position: fixed;
  left: 0;
  background-color: white;
  padding: 15px;
  box-shadow: 0 3px 6px #d5d5d5;
  z-index: 2;
  padding-top: 22px;
}

.sidebarOpen {
  left: 240px;
}

.topPositionNavbarOnly {
  top: 0px;
  height: calc(100%);
}

.topPositionWithSubNavBar {
  top: 64px;
  height: calc(100% - 33px - 33px);
}

.topPositionWithBottomBar {
  top: 104px;
  height: calc(100% - 33px - 33px - 40px);
}
  
.body {
  overflow-y: auto;
  padding-right: 10px;
}

.bodyOverflowNavbarOnly {
  max-height: 78vh;
}

.bodyOverflowWithSubNavBar {
  max-height: 74vh;
}

.bodyOverflowWithBottomBar {
  max-height: 70vh;
}

.body::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.body::-webkit-scrollbar-thumb {
border-radius: 20px;
background-color: #c4c4c4;
}

.body::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.applySection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-right: 5px;
}
.container {
    width: 27vw;
    height: calc(100vh - (102px + 34px));
    border-right: 1px solid #d6d6d6;
}

.header {
    height: 102px;
    width: 100%;
    background: #fafafa;
}

.header__title {
    display: flex;
    align-items: center;
    height: 51px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 5px;
    justify-content: space-between;
    padding-top: 20px;
}

.header__search {
    height: 51px;
    display: flex;
    align-items: center;
    padding: 10px 1rem 10px 1rem;
}

.body {
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    padding: 10px 1rem 10px 1rem;
}

.body::-webkit-scrollbar {
    width: 7px;
}

/* Handle */
.body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.body::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.header__title__back {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: -6px;
}

.header__title__text {
    display: flex;
    align-items: center;
}

.header__title__back > svg {
    width: 20px;
    color: #7a7a7a;
}

.header__title__back:hover {
    opacity: 0.8;
}

@media only screen and (max-width: 720px) {
    .container {
        width: 100vw;
    }
}
.container {
    display: flex;
    align-items: center;
    padding-left: 2px;
    padding-right: 2px;
    margin-bottom: 8px;
    margin-top: 8px;
    cursor: pointer;
}

.container:hover {
    opacity: 0.8;
}

.icon {
    margin-right: 8px;
}

.icon > svg {
    width: 24px;
    color: #708FC7;
}

.menu {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 3px;
    cursor: pointer;
  }
  
  .menu:hover {
    background-color: white;
    border-radius: 3px;
  }
  
  .menu p {
    margin: 0;
    font-size: 0.8rem;
  }
  
  .menu img {
    margin: 0;
  }
  
  .icon {
    margin-right: 7px;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .text svg {
    width: 15px;
  }

  .titleBox {
    margin-bottom: 15px;
  }
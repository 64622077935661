.chart {
    width: 115px;
    /* position: absolute;
    top: -15px;
    left: 30px; */
}

.body {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.legend {
    margin-left: 0.5rem;
}

.emptyData {
    height: 115px;
    display: flex;
    align-items: center;
}

.skeleton {
    margin-top: 0.5rem;
}
.container {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1.2rem;
    margin-bottom: 0.7rem;
    justify-content: space-between;
}

.memberWithCounter {
  position: relative;
  margin-top: -24px;
  cursor: pointer;
}
  .drawerIcon {
    margin-right: 10px;
    cursor: pointer;
    transform: rotate(180deg)
  }
  
  .drawerIcon:hover {
    opacity: 0.8;
  }

  .logoIcon > img {
    height: 20px;
  }

  .rightColumn {
    display: flex;
    align-items: center;
  }
.container {
  padding: 2rem 2.5rem;
}

.headerSection {
  display: flex;
  justify-content: space-between;
}

.titleSection h1 {
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .titleSection h1 {
    font-size: 1.5rem;
  }
}

.titleSection__creator {
  display: flex;
  margin-top: 10px;
}

.titleSection__creator__photo {
  margin-right: 7px;
  padding-top: 5px;
}

.titleSection__creator__photo img {
  margin: 0;
}

.titleSection__creator__body h1 {
  font-size: 1.1rem;
  margin: 0;
}

.titleSection svg {
  cursor: pointer;
}

.emojiPicker {
  position: absolute;
  top: 0;
  right: 0;
}

.postsSection {
  min-height: 24vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 0;
  margin-bottom: 5px;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}

.menuSection {
  position: relative;
  padding-top: 12px;
}

.archivedSection {
  display: flex;
  background-color: #fafcc2;
  align-items: center;
  padding: 6px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.archivedSection svg {
  margin-right: 6px;
}

.archivedSection h1 {
  font-size: 1.2rem;
  margin: 0;
}

.commentSection {

}

.Subscribers {
  margin-bottom: 20px;
  position: relative;
}

.subscribersHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}

.subscribersHeader h1 {
  margin: 0;
  margin-right: 10px;
}

.subscribersItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subscribersItem span {
  margin-right: 5px;
}


.cheersSection {
  margin-top: 15px;
  margin-bottom: 30px;
}

.checkboxCompletePost {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  left: -8px;
  position: relative;
  cursor: pointer;
}

.checkboxCompletePost p {
  margin: 0px;
}

.completeIconAndDate {
  display: flex;
  align-items: center;
}

.completeIconAndDate__date {
  margin-left: 10px;
}

.dateCreatorSection {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.creatorName {
  margin-top: 2px;
}

.seenSection {
  margin-bottom: 5px;
  padding-left: 5px;
}

.typingSection {
  min-height: 24px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 10px;
}
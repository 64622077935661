.container {
  padding: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.ManageLabelsContainer__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ManageLabelsContainer__Header h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.ManageLabelsContainer__Header svg {
  cursor: pointer;
}

.ManageLabelsContainer__smallTitle {
  color: #263238;
  font-size: 0.8rem;
}

.sectionSmallTitle {
  margin-top: 10px;
}

.ManageLabelsContainer__body {
  margin-top: 10px;
}

.ManageLabelsContainer__teamMember {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ManageLabelsContainer__teamMember:hover {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.ManageLabelsContainer__editIcon {
  cursor: pointer;
}

.ManageLabelsContainer__editIcon:hover {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.ManageLabelsContainer__checkIcon {
  background-color: #2196f3;
  border-radius: 30%;
  color: white;
  cursor: pointer;
}

.sectionLabel {
  display: flex;
  align-items: center;
}
.sectionLabelBody {
  flex-grow: 2;
  padding-right: 10px;
}

.sectionLabelEdit {

}

.sectionFooter {
  margin-top: 15px;
}

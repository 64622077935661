.container {
    height: 80px;
    width: 440px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 1rem;
    position: relative;
}

.information {
    width: 60%;
}

.amount {
    width: 40%;
    display: flex;
    align-items: center;
}

.amount__inputAndTitle {
    margin-right: 0.2rem;
    display: flex;
    align-items: center;
    width: 100px;
}

.amount__input {
    margin-right: 0.2rem;
}

.amount__title {
    margin-right: 1rem;
}

.amount__action {
    display: flex;
    align-items: center;
}

.title {
    margin-bottom: 0.3rem;
}

.price {
    display: flex;
    align-items: center;
}

.price__main {
    margin-right: 0.2rem;
}

.priceTotal {
    display: flex;
    align-items: center;
}

.amount__action__button > svg {
    width: 24px;
    cursor: pointer;
    margin-right: 2px;
}

.amount__action__button > svg:hover {
    opacity: 0.8;
}
.checkIcon {
    color: #42E691;
}

.cancelIcon {
    color: #FF7171;
}


@media only screen and (max-width: 1024px) {
    
}

@media only screen and (max-width: 720px) {
    .container {
        height: 130px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .information {
        width: 100%;
    }
    
    .amount {
        width: 100%;
        margin-top: 0.6rem;
    }
}

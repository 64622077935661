.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px 0px 12px;
}

.dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown:hover {
    opacity: 0.8;
}

.dropdown__arrowDown {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.dropdown__arrowDown > svg {
    width: 24px;
    color: #FDC532;
}

.plusButton {
    width: 24px;
    height: 24px;
    border: 1px solid #DEEAFF;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.plusButton > svg {
    width: 16px;
    color: #708FC7;
}

.plusButton:hover {
    background: #DEEAFF;
}
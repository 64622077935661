.container {
    width: 100%;
    height: 5px;
}
.cursor {
    cursor: pointer;
}

.cursor:hover {
    background-color: #D6D6D6;
}

.event {
    background-color: #ececec;
    border: none;
    border-radius: 0px;
}
.box {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 7px;
  border-radius: 5px;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
}

.box:hover {
  background: #dfdfdf;
}

.box .back {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.3;
}

.icon {
  position: relative;
}

.box .icon {
  position: relative;
  z-index: 2;
}

.icon svg {
  fill: #7a7a7a;
  width: 14px;
  margin-top: -3px;
}

.container {
    width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red;
    padding: 2px;
    border-radius: 3px;
    padding-right: 6px;
    padding-left: 6px;
    cursor: default;
}

.visitor {
    background-color: #0C2044;
    border-color: black;
}

.acquisition {
    background-color: #32B4FD;
    border-color: #32B4FD;
}

.activation {
    background-color: #33CB7C;
    border-color: #33CB7C;
}

.retention {
    background-color: #FF881B;
    border-color: #FF881B;
}

.revenue {
    background-color: #FFD974;
    border-color: #FFD974;
}

.referral {
    background-color: #ECECEC;
    border-color: #ECECEC;
}

.churn {
    background-color: white;
    border-color: #D6D6D6;
}
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterSection {
    display: flex;
    align-items: center;
}

.filterItem {
    position: relative;
    margin-right: 5px;
}

.reset {
    cursor: pointer;
    margin-left: 10px;
}

.reset:hover {
    opacity: 0.9;
}

.action {
    display: flex;
    align-items: center;
}

.verticalContainer {
    display: block;
}

.verticalFilterSection {
    display: block;
}

.verticalFilterItem {
    margin-right: 0px;
    margin-bottom: 5px;
}
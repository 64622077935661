.List {
  flex: 0 0 17em;
  background: #e3e3e3;
  border-radius: 7px;
  padding: 5px;
  margin: 3px;
  position: relative;
  /* Must specify specific width to the list, 
  so parents can fit the sum of all child elements */
  width: 300px;
}

.List svg {
  cursor: pointer;
}
/* .List form {
  position: absolute;
  bottom: 0;
  margin-left: 0;
  margin-right: 0;
} */

.List h1 {
  font-size: 0.9rem;
}

.container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 30px;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.container:hover {
    opacity: 0.7;
}

.collapsible {
    position: absolute;
    top: 2px;
    left: 3px;
}

.textAndIconRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.iconLeft {
    margin-right: 0.5rem;
    margin-left: -3px;
}

.iconLeft > svg {
    width: 20px;
}

.collapsible > svg {
    width: 20px;
}

.iconRightGeneral > svg {
    width: 20px;
}

.iconRightCounter {
    margin-right: 0.4rem;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
}

.image {
    margin-right: 2rem;
}

.image > img {
    width: 250px;
}

.headline {
    margin-bottom: 0.3rem;
}


@media only screen and (max-width: 1024px) {
    .image > img {
        width: 225px;
    }
}

@media only screen and (max-width: 720px) {
    .container {
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .image {
        margin-bottom: 1.2rem;
    }

    .image > img {
        width: 200px;
    }
}
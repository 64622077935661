.form {
    margin-bottom: 15px;
}

.formText {
    margin-bottom: 10px;
}

.action {
    margin-top: 1.3rem;
    display: flex;
    justify-content: flex-end;
}

.button {
    margin-right: 0.5rem;
}

.button:last-child {
    margin-right: 0px;
}

.alertClickSection {
    margin-top: 1rem;
}

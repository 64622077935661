.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

.headerSection svg {
  cursor: pointer;
}

.headerSection h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.bodySection {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodySection p {
  font-size: 0.7rem;
  margin-bottom: 0;
  text-decoration: none;
}

.menu {
  margin-bottom: 3px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
}


.menu:hover {
  background-color: #ebecf1;
  border-radius: 5px;
}


.menu__leftSection {
  display: flex;
  align-items: center;
}
.actionSection {
  margin-top: 20px
}


.icon {
  width: 15px !important;
  color: green;
  margin-left: 4px;
}

.iconBlocked {
  color: red;
}

.selectedIcon {
  width: 15px !important;
}
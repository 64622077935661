.bodySection {
  background: #FFF;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ECECEC;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerSection h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.headerSection svg {
  cursor: pointer;
  margin: 0;
}

.iconRight {
  display: flex;
  align-items: center;
}

.iconRight__cheers {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  background-color: #f8f9fa;
  border-radius: 100%;
  margin-right: 15px;
  cursor: pointer;
  box-shadow: 0 1px 3px #ccc;
}

.iconRight__cheers > img {
  width: 15px;
}

.iconRight__cheers:hover {
  opacity: 0.8;
}

.newSection {
  margin-bottom: 5px;
}

/* .newSection__header p {
  cursor: pointer;
  text-decoration: underline;
} */

.newSection__header p:hover {
  opacity: 0.8;
}

.newSection__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.newSection__header > h1 {
  margin-bottom: 0;
}

.newSection__body {
  max-height: 60vh;
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.newSection__body::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.newSection__body::-webkit-scrollbar-thumb {
border-radius: 20px;
background-color: #c4c4c4;
}

.newSection__body::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.actionSection {
  margin-top: 10px;
}

.emptyNotif {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.emptyNotif img {
  width: 100px;
  margin-bottom: 10px;
}

.emptyNotif h1 {
  margin: 0;
}

.emptyNotif > p {
  margin-top: 5px;
  font-size: 0.9rem;
}

.infiniteScroll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}

.infiniteScroll > p {
  font-size: 0.8rem;
}

.markReadSection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.markReadSection > p {
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 0.8rem;
  color: #708FC7;
}

/* .markReadSection > svg {
  color: grey;
  cursor: pointer;
  margin-right: 6px;
}

.markReadSection > svg:hover {
  opacity: 0.8;
} */

.checkBoxRead { 
  color: #7A7A7A;
  cursor: pointer;
  /* margin-right: 10px; */
}

.checkBoxRead:hover {
  opacity: 0.8;
}

.checkBoxUnread { 
  color: #7A7A7A;
  cursor: pointer;
  /* margin-right: 10px; */
}

.checkBoxUnread:hover {
  opacity: 0.8;
}

.selectAllSection {
  /* margin-top: 15px;
  margin-bottom: 5px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  cursor: pointer;
}

.selectAllSection > p {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-right: 5px;
  color: #708FC7;
  text-decoration: none;
}

.selectAllSection__uncheckIcon {
  cursor: pointer;
}

.leftHeaderSection {
  display: flex;
  align-items: center;
}

.leftHeaderSection__cheers {
  margin-right: 7px;
}
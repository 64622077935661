.columnInvoice {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    height: 70px;
    border-top: 1px solid #ececec;
    /* border-bottom: 1px solid #ececec; */
}

.columnInvoice__invNumber {
    /* width: 60px; */
    width: 7%;
    margin-right: 0.7rem;
}

.columnInvoice__status {
    /* width: 60px; */
    width: 6%;
    margin-right: 1.1rem;
}

.columnInvoice__expDate {
    /* width: 120px; */
    width: 12%;
    margin-right: 0.8rem;
}


.columnInvoice__description {
    /* width: 320px; */
    width: 20%;
    margin-right: 1.3rem;
}

.columnInvoice__discAmount {
    /* width: 100px; */
    width: 10%;
    margin-right: 4px;
}

.columnInvoice__amount {
    /* width: 100px; */
    width: 10%;
    margin-right: 4px;
}

.columnInvoice__action {
    margin-left: 10px;
    /* width: 100px; */
    /* width: 10%; */
    margin-right: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.columnInvoice__description__duration {
    margin-top: 2px;
}

.columnInvoice__description__estSubscriptionDate {
    margin-top: 2px;
}

.columnInvoice__LinkAndExpire {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 20%;
    /* width: 100%; */
}
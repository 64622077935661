.container {
    min-height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.container:hover {
    opacity: 0.8;
}

.containerSelected {
    background-color: #f7f7f7;
    border-radius: 2px;
}

.containerOpen {
    background-color: #FFEEC3;
    border-radius: 2px;
}

.colorAndTitle {
    display: flex;
    align-items: center;
    width: 165px;
    min-height: 30px;
}

.color {
    margin-right: 0.7rem;
    margin-left: 0.2rem;
}

.color__icon {
    width: 14px;
    height: 14px;
    border-radius: 10px;
}

.menu {
    width: 24px;
    height: 24px;
    background-color: #fafafa;
    border-radius: 4px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu > svg {
    width: 18px;
}
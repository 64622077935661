.Folder {
  margin-bottom: 15px;
  margin-right: 10px;
  cursor: pointer;
  
  width: 200px;
  height: 250px;
  border: 1px solid #a6a6a4;
  border-radius: 8px;
  overflow: hidden;
}

.border {
  height: 15px;
  background-color: lightgrey;
  position: relative;
}

.insideBorder {
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 15px solid white;
    border-right: 25px solid transparent;
    height: 0;
    width: 130px;
}

.main {
  padding: 15px 20px 15px 20px;
  max-width: 100%;
  max-height: 100%;
}

.main__title h1 {
  font-size: 1.1rem;
  overflow-wrap: break-word;
}

.main__content {
  display: flex;
  flex-wrap: wrap;
}

.main__content p {
  overflow-wrap: break-word;
}



/* Add comment section */

.AddCommentContainer {
  display: flex;
  margin-bottom: 20px;

}

.inputComment {
  width: 100%;
}

.formInitial {
  cursor: pointer;
}

.actionSection {
  margin-top: 7px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.actionSection svg {
  margin-left: 7px;
  cursor: pointer;
  width: 20px;
}

/* targetting emoji pop up  */
.emojiButton > div > div {
  top: 0;
  right: 2vw;
}

@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .emojiButton > div > div {
    top: 0;
    left: 0;
    right: 0;
  }
}

.editorSection {
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 3px solid #FDC532;
}

.borderEditor {
  margin: -1px;
}
.container {
    height: 100%;
    background-color: white;
}

.list {
    max-height: 47vh;
    overflow-y: auto;
    height: 47vh;
    /* padding: 0px 0px 12px 0px; */
    padding: 0.5rem;
    overflow-x: hidden;
    /* width: 100%; */
}


.list::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.list::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}
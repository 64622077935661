.container {
    display: flex;
    padding: 5px;
    height: 16px;
    width: 16px;
    border-radius: 10px;
    margin-right: 3px;
    align-items: center;
    justify-content: center;
  }

  .overdue {
    background-color: #FF7171;
  }

  .dueLater {
    background-color: #ebecf1;
  }

  .dueSoon {
    background-color: #FDC532;
  }

  .completed {
    background-color: #42E691;
  }

  .container > svg {
    width: 10px;
    color: black;
  }
.Question {
  display: block;
  /* margin-bottom: 8px; */
  cursor: pointer;
  /* padding: 20px 25px 20px 25px; */
  padding: 15px 15px 15px 15px;
  /* border: 1px solid #a6a6a4;
  border-radius: 5px; */
  /* margin-right: 20px;
  margin-left: 20px; */
  background-color: white;
}


.borderSemiRounded {
  border-radius: 5px;
}

.borderSquare {
  border-radius: 0px;
}

.boderSquareSemiRoundedTopLeft {
  border-radius: 0px;
  border-top-left-radius: 5px;
}

.boderSquareSemiRoundedTopRight {
  border-radius: 0px;
  border-top-right-radius: 5px;
}

.boderSquareSemiRoundedBottomLeft {
  border-radius: 0px;
  border-bottom-left-radius: 5px;
}

.boderSquareSemiRoundedBottomRight {
  border-radius: 0px;
  border-bottom-right-radius: 5px;
}

.borderLineRegularThin {
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
}

.borderLineDisabled {

}

.mainContainer {
  display: flex;
  align-items: flex-start;
}

.photoSection {
  margin-right: 10px;
}

.photoSection img {
  margin: 0;
}

.contentSection__header {
  display: flex;
  align-items: center;
}

.contentSection__header__title {
  margin-right: 5px;
}

.contentSection__header__title h1 {
  margin: 0;
}


.contentSection__content__creator {
  color: #0f4c75;
}


@media only screen and (max-width: 600px) {

  .contentSection p {
    font-size: 0.8rem;
  }
}

.subscribers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.Doc {
    margin-bottom: 2px;
    margin-right: 2px;
    cursor: pointer;
    padding: 2px 4px 2px 4px;
    width: 50px;
    height: 70px;
    border: 1px solid #a6a6a4;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .main {
    max-width: 100%;
    max-height: 100%;
  }
  
  .main__title h1 {
    font-size: 0.7rem;
    overflow-wrap: break-word;
  }
  
  .main__title svg {
    fill: black;
    margin: 0px;
    width: 10px;
  }
  
  .main__content p {
    font-size: 0.5rem;
    overflow-wrap: break-word;
  }
  
  
  
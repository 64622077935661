.teamName {
  margin-bottom: 5px;
  padding-right: 20px;
}

.teamDesc {
  margin-bottom: 5px;
  padding-right: 20px;
}

.actionSection {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.actionSection svg {
  margin-left: 7px;
  cursor: pointer;
  width: 20px;
}

.icon {
  cursor: pointer;
}
.container {
    width: 73vw;
    height: calc(100vh - (34px + 33px));
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sidebarOpen {
    width: 61.7vw;
}

.image {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.loadingContainer {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    /* background-color: #ebecf1; */
    border-radius: 5px;
    width: 100%;
    /* height: 40px; */
    padding: 0.5rem;
}

.fileName {
    width: 90%;
  }
  
  .iconPending > svg {
    color: grey;
  }

  .iconSuccess > svg {
    color: #42E691;
  }


  .spinner {
    margin-top: 6px;
    margin-right: 4px;
  }
.mediumTitle {
  color: #263238;
  font-size: 1rem;
  font-weight: 500;
}

.container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.container:hover {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.iconSection {
  min-width: 150px;
  width: 150px;
  height: 90px;
  background-color: #ebecf1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .iconSection {
    min-width: 90px;
    width: 90px;
    height: 60px;
  }
}

.titleSection {
  display: flex;
  cursor: pointer;
  width: 90%;
  
}

.titleSection h1 {
  max-width: 70%;
}

.titleSection svg {
  cursor: pointer;
  width: 15px;
  margin-left: 5px;
}

.contentActionSection {
  width: 100%;
  margin-left: 10px;
}

.contentActionSection h1 {
  font-size: 0.9rem;
}

.contentActionSection p {
  font-size: 0.9rem;
  line-height: 1;
  margin: 0;
}

.askDropContainer {
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #ebecf1;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  padding: 10px;
  /* flex-wrap: wrap; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */
}



.loadingContainer {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  background-color: #ebecf1;
  border-radius: 5px;
  width: 100%;
  height: 80px;
}

.actionSection {
  display: flex;
  align-items: center;
  position: relative;
}

.actionSection p {
  font-size: 0.8rem;
}

.wrapper {
  margin-right: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  /* z-index: 10; */
}

.wrapper p {
  font-size: 0.8rem;
  text-decoration: underline;
  line-height: 1;
}

.wrapper p:hover {
  opacity: 0.7;
}

.wrapper span {
  font-size: 0.8rem;
  cursor: text;
}

.wrapper svg {
  width: 15px;
}

.text {
  margin-right: 0.3rem;
}

.icon > svg {
  color: grey;
}
.container {
    width: 36px;
    height: 36px;
    background: #FAFAFA;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.container > svg {
    width: 20px;
    color: #7a7a7a;
}

.container:hover {
    opacity: 0.8;
}
.container {
    display: flex;
    justify-content: space-between;
    height: 40px;
    background-color: #ffffff;
    position: sticky;
    position: -webkit-sticky; /* Safari */
    top: 33px;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4px;
    padding-bottom: 5px;
    z-index: 3;
    /* box-shadow: 0 1px 4px #d6d6d6; */
    border-bottom: 1px solid #d6d6d6;
    transition: margin-left 0.3s ease;
}

.sidebarOpen {
    margin-left: 240px;
    width: calc(100% - 240px);
}

.title {
    display: flex;
    align-items: center;
}

.title__archivedItems {
    margin-right: 14px;
}

.title__settings {
    margin-right: 14px;
    cursor: pointer;
}

.title__settings:hover {
    opacity: 0.8;
}

.title__settings > svg {
    color: #B5B5B5;
    width: 24px;
}

.title__text {
    margin-right: 14px;
}

.memberAndRole {
    display: flex;
    align-items: center;
}

.role {
    margin-left: 1rem;
    margin-right: 1rem;
}

@media only screen and (max-width: 1200px) {
   .sidebarOpen {
    margin-left: 50px;
    width: calc(100% - 50px);
   }
}

@media only screen and (max-width: 720px) {
    .title__settings {
        margin-right: 6px;
    }

    .title__archivedItems {
        margin-right: 6px;
    }

    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .sidebarOpen {
        margin-left: 50px;
        width: calc(100% - 50px);
       }

       .role {
        margin-left: 10px;
        margin-right: 4px;
    }
  }
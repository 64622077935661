.title {
    margin-bottom: 0.5rem;
}

.search {
    margin-bottom: 0.3rem;
}
.list {
    max-height: 20.8vh;
    overflow-y: auto;
    height: 20.8vh;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
    width: 100%;
}


.list::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.list::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}
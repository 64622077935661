.container {
    display: flex;
    align-items: center;
}

.title {
    margin-bottom: 1rem;
}

.description {
    margin-bottom: 1.2rem;
}

.illustration {
    width: 230px;
}

.illustration > img {
    width: 200px;
}

.cta {
    display: flex;
}

.ctaButton {
    margin-right: 0.8rem;
}

@media only screen and (max-width: 1024px) {
    .illustration > img {
        width: 170px;
    }
}
  
@media only screen and (max-width: 720px) {
    .container {
        width: auto;
        display: block;
        padding-bottom: 2rem;
    }  

    .illustration {
        display: flex;
        justify-content: center;
        width: auto;
    }
    .illustration > img {
        width: 150px;
    }
    
    .title {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }
    
    .description {
        display: flex;
        justify-content: center;
    }

    .cta {
        display: flex;
        justify-content: center;
    }
}

.container {
    width: auto;
    max-width: 150px;
    height: 30px;
    background: #FFF7E1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
}

.container:hover {
    opacity: 0.8;
}

.icon > svg {
    width: 20px;
    color: #F0B418;
}

.text {
    width: 100%;
    margin-left: 3px;
    display: flex;
    align-items: center;
}

.outlinedContainer {
    width: auto;
    max-width: 150px;
    height: 30px;
    background: #FFFFFF;
    /* border: 1px solid #D6D6D6;
    box-sizing: border-box;
    border-radius: 4px; */
    display: flex;
    align-items: center;
    /* padding-left: 12px;
    padding-right: 12px; */
    cursor: pointer;
    margin-right: 16px;
    position: relative;
    justify-content: center;
}

.outlinedContainer:last-child {
    margin-right: 0;
}

.outlinedIcon > svg {
    width: 20px;
    color: #7a7a7a;
}

.outlinedContainer:hover {
    opacity: 0.8;
}

.passiveContainer {
    width: auto;
    max-width: 150px;
    height: 30px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    justify-content: center;
}

.passiveContainer:last-child {
    margin-right: 0;
}

.passiveIcon > svg {
    width: 20px;
    color: #7a7a7a;
}

.passiveContainer:hover {
    opacity: 0.8;
}

.activeContainer {
    width: auto;
    max-width: 150px;
    height: 30px;
    background: #7a7a7a;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    justify-content: center;
}

.activeContainer:last-child {
    margin-right: 0;
}

.activeIcon > svg {
    width: 20px;
    color: #ffffff;
}

.activeContainer:hover {
    opacity: 0.8;
}

@media only screen and (max-width: 720px) {
    .outlinedContainer {
        padding: 0;
        min-width: 30px;
        height: 30px;
        border-radius: 5px;
    }

    .container {
        margin-right: 0px;
    }
}
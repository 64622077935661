.showEvents__header {
    display: flex;
    margin-bottom: 10px;
}

.showEvents__header > h1 {
    font-size: 0.8rem;
    margin: 0;
}

.boxDate {
    min-width: 15px;
    height: 15px;
    width: 15px;
    margin-right: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
  }
  
  .boxDate svg {
    fill: #0f3057;
    width: 15px;
  }
  
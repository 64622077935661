.main {
    margin-bottom: 2px;
    margin-right: 2px;
    cursor: pointer;
    padding: 2px 4px 2px 4px;
    width: 50px;
    height: 70px;
    border: 1px solid #a6a6a4;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }
  .mainBig {
    padding: 15px 20px 15px 0;
    width: 400px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .main__icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .main__title {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  
  .main__title h1 {
    font-size: 0.7rem;
    overflow-wrap: break-word;
  }
  .main__title a {
    text-decoration: underline;
    color: blue;
  }
  
  .main__content p {
    overflow-wrap: break-word;
  }
  
  .main__icon img {
    max-width: 40px;
    max-height: 30px;
    cursor: pointer;
  }
  
  .main__title svg {
    fill: black;
    margin: 0px;
    width: 10px;
  }
  
  @media only screen and (max-width: 600px) {
    .main__icon img {
      max-width: 40px;
    }
  }

  .playIcon > svg {
    width: 8px;
    height: 8px;
    position: absolute;
    top: 10px;
    left: 15px;
    color: white;
  }
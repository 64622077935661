/* Comments Section */

.mediumTitle {
    color: #263238;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .commentsSection {
    display: flex;
    margin-bottom: 30px;
    max-width: 100%;
    word-break: break-all;
  }
  
  .commentHeaderTextActionSection {
    flex-grow: 1;
    /* max-width: 90%; */
    position: relative;
  }
  
  
  .commentTextContainer {
    margin-top: 5px;
    margin-bottom: 6px;
    margin-right: 5px;
    padding: 6px;
    /* background-color: #ebecf1; */
    /* background-color: #f7f7f7; */
    border-radius: 10px; 
    border: 1px solid #D6D6D6;
    position: relative;
  }

  .commentMenuSection {
    display: inline-block;
    position: absolute;
    right: 4px;
    top: 0px;
    /* width: 150px; */
    height: 26px;
    border-radius: 100%;
    /* background-color: #fafafa; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .seenSection {
    margin-right: 0.7rem;
  }
  
  .commentActionContainer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  
  
  .commentHeaderContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  /* .commentHeaderContainer span {
    text-decoration: underline;
    font-size: 0.8rem;
  } */
  
  .commentUser {
    font-weight: bold;
    margin: 0;
    margin-right: 8px;
  
  }
  

.discussSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.discussSection__info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.discussSection__lastReply {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.discussSection__newReply__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.discussSection__newReply__item svg {
  width: 14px;
  margin: 0;
  margin-right: 6px;
  color: #7A7A7A;
}

.discussSection p {
  margin-bottom: 0;
  font-size: 0.7rem;
}

.discussSection__info__countText {
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discussSection__info__countText > svg {
  width: 14px;
  color: #708FC7;
  margin-right: 4px;
}

.discussSection__info__dateText {
  margin-right: 5px;
  color: #4b5d67;
}

.cheersSection {
  margin-top: 4px;
  margin-bottom: 7px;
}

.lastReplyText {
  margin-right: 5px;
}


@media only screen and (max-width: 1024px) {
  .seenSection {
    margin-right: 0.4rem;
  }
}

@media only screen and (max-width: 720px) {
  .seenSection {
    margin-right: 0px;
  }
}
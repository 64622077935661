.container {
    width: 100%;
    display: flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: beige;
    padding: 1rem;
}

.col_companyName {
    width: 20%;
}

.col_picName {
    width: 15%;
}

.col_picEmail {
    width: 20%;
}

.col_picPhone {
    width: 25%;
}

.col_category {
    width: 10%;
}
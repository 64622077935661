.headerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
}

.headerSection svg {
    cursor: pointer;
}

.headerSection h1 {
    font-size: 0.8rem;
    margin-bottom: 0;
}

.bodySection {
    margin-top: 5px;
}

.bodySection p {
    font-size: 0.8rem;
    margin-bottom: 0;
    text-decoration: none;
    cursor: default;
}

.actionSection {
    margin-top: 20px
}
  

.form {
    margin-top: 15px;
    margin-bottom: 10px;
}
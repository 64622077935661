.title {
    margin-bottom: 1rem;
}

.successCriteria {
    margin-bottom: 1rem;
}

.milestone > img {
    width: 500px;
}
.container {
    position: absolute;
    width: 421px;
    top: 40px;
    left: 18px;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 5;
    box-shadow: 1px 2px 8px rgba(151, 151, 151, 0.2);
}

.header {
    height: 26px;
    background: #FDC532;
    padding: 5px;
}

.body {
    height: 50px;
    background: #ffffff;
    padding: 5px;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    /* Disable scrollbar */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Disable Scrollbar */
.body::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 720px) {
    .container {
        width: 300px;
    }
  }
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.container > svg {
    width: 24px;
    color: #B5B5B5;
}

.container:hover {
    opacity: 0.8;
}
.container {
    /* min-width: 400px; */
    width: 100%;
    /* height: 59.3vh; */
    background-color: white;
    border-radius: 4px 4px 0px 0px;
    margin-left: 1rem;
    padding: 1rem;
}

.body {
    margin-top: 1rem;
    padding: 0.3rem;
    display: flex;
    justify-content: space-between;
}

.item {
    margin-bottom: 1rem;
    width: 50%;
    padding-right: 0.5rem;
}

.item:last-child {
    margin-bottom: 0px;
}

@media only screen and (max-width: 1024px) {
    .container {
        margin-left: 0px;
        margin-top: 1rem;
    }
}

@media only screen and (max-width: 720px) {
    .container {
        margin-left: 0px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        height: 350px;
    }
}
/* .ResetThenCreateNewPasswordPage {
  height: 95vh;
  display: grid;
  place-items: center;
} */

.ResetThenCreateNewPasswordPage__container {
  background-color: white;
  /* border-radius: 10px; */
  box-shadow: 0 2px 5px #ccc;
  width: 100vw;
  min-height: 100vh;
  display: flex;
}

.ResetThenCreateNewPasswordPage__container > img {
  object-fit: contain;
  height: 40px;
  margin-bottom: 20px;
}

.ResetThenCreateNewPasswordPage__button {
  margin-top: 30px;
}

.ResetThenCreateNewPasswordPage__insideButton {
  display: flex;
  align-items: center;
  margin:auto;
}

.ResetThenCreateNewPasswordPage__insideButton > span {
  margin-left: 5px;
  font-size: 0.9rem;
}

.ResetThenCreateNewPasswordPage__insideButton > svg {
  width: 1rem;
}

.ResetThenCreateNewPasswordPage__textSection > h1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  color: #0C2044;
}

.ResetThenCreateNewPasswordPage__textSection > p {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #7A7A7A;
}

.ResetThenCreateNewPasswordPage__leftSection {
  min-width: 50vw;
}

.ResetThenCreateNewPasswordPage__rightSection {
  min-width: 50vw;
  padding-top: 25vh;
  padding-left: 80px;
  padding-right: 90px;
  padding-bottom: 1rem;
}

.ResetThenCreateNewPasswordPage__rightSection__loginButton {
  margin-top: 30px;
}

.ResetThenCreateNewPasswordPage__leftSection__illustration {
  max-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: auto;
}

.subtitle {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1024px) {
  .ResetThenCreateNewPasswordPage__container {
    width: auto;
    display: block;
  }

  .ResetThenCreateNewPasswordPage__rightSection { 
    padding-top: 3vh;
    padding-left: 50px;
    padding-right: 50px;
  }

  .ResetThenCreateNewPasswordPage__leftSection__illustration {
    width: 100%;
    min-height: 0;
  }
}

@media only screen and (max-width: 720px) {
  .ResetThenCreateNewPasswordPage__textSection > h1 {
    font-size: 36px;
    line-height: 33px;
  }
  
  .ResetThenCreateNewPasswordPage__textSection > p {
    font-size: 14px;
  }
}
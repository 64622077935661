.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.marginRight {
    margin-right: 5px;
}

.link {
    cursor: pointer;
}

.link:hover {
    opacity: 0.8;
}
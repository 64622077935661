.container {
    padding: 3rem;
}

.title {
    
}

.durationSection {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
}

.durationButton {
    margin-right: 5px;
}

.planCardSection {
    width: 85vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.scrollPlanCards {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 10px;
}

.scrollPlanCards::-webkit-scrollbar {
    height: 6px;
}

/* Handle */
.scrollPlanCards::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.scrollPlanCards::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.planCard {
    margin-right: 15px;
}

.footer {
    margin-top: 6px;
}

@media only screen and (max-width: 1024px) {
    .planCardSection {
        width: 100vw;
        /* padding-left: 0.8rem; */
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 720px) {
    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .planCardSection {
        width: 200vw;
        padding-left: 0.8rem;
        justify-content: flex-start;
    }
}
.container {
    
}

.teamSection {
    margin-bottom: 0.3rem;
}

.searchSortCreate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.2rem;
}

.sortAndCreate {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.actionSortCreate > svg {
    width: 20px;
}

.actionSortCreate {
    margin-right: 2px;
}

.actionSortCreate:last-child {
    margin-right: 0px;
}

.actionSortCreate:hover {
    opacity: 0.8;
}
.container {
    height: 40px;
    background-color: white;
    width: 100%;
    position: sticky;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    max-width: 100vw;
    top: 0px;
    left: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.brand {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.logo {
    margin-right: 10px;
}

.logo > img {
    height: 20px;
}

.menu {
    display: flex;
    align-items: center;
}

.menuItem {
    margin-right: 10px;
}
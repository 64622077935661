.container {
  padding: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.headerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
  }
  
  .headerSection svg {
    cursor: pointer;
  }
  
  .headerSection h1 {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  
  .bodySection {
    margin-top: 5px;
    cursor: text;
  }
  
  .bodySection p {
    font-size: 0.9rem;
    margin-bottom: 0;
    text-decoration: none;
  }
  
  .menu {
    margin-bottom: 3px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  
  }
  
  .menu:hover {
    background-color: #ebecf1;
    border-radius: 5px;
  }
  
  .actionSection {
    margin-top: 20px
  }
  
  
  .icon {
    width: 15px !important;
    color: green;
    margin-left: 7px;
  }
  
  .iconUnset {
    width: 15px !important;
    margin-left: 7px;
  }
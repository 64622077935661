.main {
  margin-bottom: 15px;
  margin-right: 10px;
  cursor: pointer;
  padding: 15px 20px 15px 20px;
  width: 200px;
  height: 250px;
  border: 1px solid #a6a6a4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.mainBig {
  padding: 15px 20px 15px 0;
  width: 400px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main__icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main__title {
  width: 100%;
  height: 100%;
  text-align: center;
}

.main__title h1 {
  font-size: 1.1rem;
  overflow-wrap: break-word;
}
.main__title a {
  text-decoration: underline;
  color: blue;
}

.main__content p {
  overflow-wrap: break-word;
}

.main__icon {
  position: relative;
  cursor: pointer;
}

.main__icon img {
  max-width: 350px;
  max-height: 200px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .main__icon img {
    max-width: 300px;
  }
}

.playIcon > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50px;
  left: 70px;
  color: white;
}

.playIconBig > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 80px;
  left: 180px;
  color: white;
}
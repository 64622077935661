.container {
  padding: 1rem 15vw;
}

.sidebarOpen {
  margin-left: 240px;
  padding-left: 7vw;
  padding-right: 7vw;
}

.headerSection {
  /* display: flex;
  align-items: center; */
}

.bodySection {
  height: 72vh;
  display: block;
  position: relative;
  background-color: white;
}

.mainContainer {
  max-height: 72vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
}

.mainContainer::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.mainContainer::-webkit-scrollbar-thumb {
border-radius: 20px;
background-color: #c4c4c4;
}

.mainContainer::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}


.markReadSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 37px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.markReadSection > p {
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 0.8rem;
  color: grey;
  cursor: pointer;
}

.emptyNotif {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.emptyNotif img {
  width: 100px;
  margin-bottom: 10px;
}

.emptyNotif h1 {
  margin: 0;
}

.infiniteScroll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}


.checkBoxRead { 
  color: #42E691;
  cursor: pointer;
  margin-right: 10px;
}

.checkBoxCancel { 
  color: #FF7171;
  cursor: pointer;
  margin-right: 10px;
}

.checkBoxRead:hover {
  opacity: 0.8;
}

.checkBoxUnread { 
  color: grey;
  cursor: pointer;
  margin-right: 10px;
}

.checkBoxUnread:hover {
  opacity: 0.8;
}

.selectAllSection {
  /* margin-top: 15px;
  margin-bottom: 5px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-right: 10px; */
  cursor: pointer;
}

.selectAllSection > p {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-right: 5px;
  color: grey;
  text-decoration: none;
}

.selectAllSection__uncheckIcon {
  cursor: pointer;
}

.wrapperNotification {
  margin-bottom: 5px;
}

.categoryTabSection {
  margin-bottom: 10px;
}

.cheersButtonSection {
  margin-right: 10px;
}

@media only screen and (max-width: 720px) {
  .container {
    padding: 1rem 5vw;
  }
}
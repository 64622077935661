.container {
  background: #ffffff;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 130px;
  width: 130px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  text-align: left;
  z-index: 5 !important;
  position: absolute;
  top: 40px;
  left: 12px;
}

.text {
  height: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 14px;
  cursor: pointer;
}

.text:hover {
  background: #FFEEC3;
}

.text:hover .title {
  font-weight: 700 !important;
}
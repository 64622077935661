.container {
    position: relative;
    width: 100%;
    padding-top: 0.3rem;
}

.title {
    margin-bottom: 1rem;
}

.backButton {
    position: absolute;
    top: -1.7rem;
    left: -0.4rem;
    cursor: pointer;
}

.description {
    margin-bottom: 2rem;
}

.list {
    max-height: 250px;
    overflow-y: auto;
    height: 250px;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
}

.list::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.list::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.action {
    margin-top: 2rem;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #D6D6D6;
    padding: 1rem;
    width: 100%;
}

.userRoleSection {
    margin-bottom: 1rem;
}

@media only screen and (max-width: 1024px) {
    .container {
        
    }
}

@media only screen and (max-width: 720px) {
    /* .container {
        padding: 2rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    } */
}
.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.container:hover {
    background-color: #FFEEC3;
}

.selectedTrue {
    background-color: #FFE49B;
}

.editMenuBox {
    position: relative;
    max-width: 400px;
}
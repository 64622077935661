.container {
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.icon {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon > svg {
    width: 26px;
    color: #FDC532;
}

.text {
    display: flex;
    align-items: center;
    margin-left: 5px;
    height: 100%;
    width: 100%;
}

.text > input {
    width: 100%;
    margin-right: 5px;
    position: relative;
    outline: 0;
    border: 0;
    resize: none;
}

.text > input::placeholder {
    font-size: 11px;
    color: #c4c4c4;
    transform:translate3d(0,-1px,0)
}
.container {
    position: relative;
}

.icon {
    border-radius: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon > svg {
    position: absolute;
    color: white;
}

.cardAttachmentContainer {
    width: 120px;
    height: 80px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardAttachmentIcon {
    position: absolute;
    top: 25px;
    left: 45px;
}

.cardAttachmentIcon {
    width: 30px;
    height: 30px;
}

.cardAttachmentIcon > svg {
    width: 28px;
    height: 28px;
}


.chatContainer {
    width: 400px;
    height: 220px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatIcon {
    position: absolute;
    top: 80px;
    left: 167px;
}

.chatIcon {
    width: 60px;
    height: 60px;
}

.chatIcon > svg {
    width: 50px;
    height: 50px;
}



.bucketFileContainer {
    width: 190px;
    height: 140px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bucketFileIcon {
    position: absolute;
    top: 55px;
    left: 60px;
}

.bucketFileIcon {
    width: 36px;
    height: 36px;
}

.bucketFileIcon > svg {
    width: 32px;
    height: 32px;
}




.fileContainer {
    width: 400px;
    height: 220px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileIcon {
    position: absolute;
    top: 80px;
    left: 167px;
}

.fileIcon {
    width: 60px;
    height: 60px;
}

.fileIcon > svg {
    width: 50px;
    height: 50px;
}



.fileSnapshotContainer {
    width: 60px;
    height: 25px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileSnapshotIcon {
    position: absolute;
    top: 5px;
    left: 12px;
}

.fileSnapshotIcon {
    width: 16px;
    height: 16px;
}

.fileSnapshotIcon > svg {
    width: 14px;
    height: 14px;
}




.chatSnapshotContainer {
    width: 60px;
    height: 35px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatSnapshotIcon {
    position: absolute;
    top: 9px;
    left: 22px;
}

.chatSnapshotIcon {
    width: 16px;
    height: 16px;
}

.chatSnapshotIcon > svg {
    width: 14px;
    height: 14px;
}
.Paper {
  width: 100%;
  min-height: 450px;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;
  padding: 30px;
  margin: 0 16px 0 0;
  display: inline-block;
}


@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .Paper {
    margin: 0;
    border-radius: 0;
  }
}
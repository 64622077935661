.container {
    display: flex;
    align-items: center;
}

.containerClick {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.containerClick:hover {
    opacity: 0.8;
}

.blackIcon > svg {
    color: #B5B5B5;
    width: 16px;
    margin-right: 3px;
}

.greyIcon > svg {
    color: #B5B5B5;
    width: 16px;
    margin-right: 3px;
}
.SeparatorLine {
  height: 1px;
  width: 100%;
  background-color: lightgrey;
  border-radius: 10px;
  display: block;
  margin-top: 2%;
  margin-bottom: 2%;
  cursor: default;
}

.SeparatorLineDark {
  background-color: white;
}

.container {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.container:hover {
    background-color: #f7f7f7;
    border-radius: 5px;
}

.notifSection {
    padding: 3px;
    display: flex;
    border-radius: 5px;
    border: 1px solid #C1D6FD;
    width: 370px;
    /* box-shadow: 0 1px 3px #ccc; */
}

.creatorAndBox {
    display: inline-flex;
    align-items: flex-start;
    cursor: pointer;
}

.creator {
    margin-right: 5px;
    position: relative;
    padding-top: 3px;
    padding-right: 5px;
    height: 38px;
}

.titleContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap-reverse;
    padding: 5px;
    /* padding-left: 10px; */
    justify-content: center;
}

.titleContent > h1 {
    font-size: 1rem;
    overflow-wrap: anywhere;
}

.titleContent > p {
    font-size: 0.9rem;
    overflow-wrap: anywhere;
}

.cheersSection {
    display: flex;
    align-items: center;
    /* background-color: #ebecf1; */
    /* background-color: #FFEEC3; */
    padding: 5px;
    border-radius: 20px;
    flex-wrap: wrap;
    margin-top: 5px;
}

.notesSection {
    margin-top: 10px;
    font-size: 0.6rem !important;
}

.titleContent__title {
    margin-bottom: 3px;
}

.titleContent__team {
    margin-top: 15px;
}

@media only screen and (max-width: 600px) {
    /* .container {
     display: block;
    }

    .cheersSection {
        margin-top: -10px;
        margin-left: 10px;
    } */
}
.container {
    padding-right: 8rem;
    padding-left: 8rem;
}
.title {
    margin-bottom: 1rem;
}

.list {
    max-height: 350px;
    overflow-y: auto;
    height: 350px;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
    width: 100%;
}


.list::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.list::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.item {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.action {
    margin-top: 2rem;
}

@media only screen and (max-width: 1024px) {
   
}
  
@media only screen and (max-width: 720px) {
    .list {
        max-height: 320px;
        height: 320px;
    }

    .container {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.Event {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 8px; */
  cursor: pointer;
  padding: 5px 150px 5px 10px;
  background: white;
}

.photoContentSection {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  .Event {
    padding: 5px 10px 5px 0;
  }
}

.photoSection {
  margin-right: 30px;
}

.photoSection img {
  margin: 0;
}

.contentSection__header {
  display: flex;
  flex-wrap: wrap;
}

.contentSection__header__title {
  margin-right: 5px;
}

.contentSection__header__title h1 {
  margin: 0;
}


.contentSection__content__creator {
  color: #0f4c75;
}


.borderSemiRounded {
  border-radius: 5px;
}

.borderSquare {
  border-radius: 0px;
}

.boderSquareSemiRoundedTopLeft {
  border-radius: 0px;
  border-top-left-radius: 5px;
}

.boderSquareSemiRoundedTopRight {
  border-radius: 0px;
  border-top-right-radius: 5px;
}

.boderSquareSemiRoundedBottomLeft {
  border-radius: 0px;
  border-bottom-left-radius: 5px;
}

.boderSquareSemiRoundedBottomRight {
  border-radius: 0px;
  border-bottom-right-radius: 5px;
}

.Folder {
    margin-bottom: 2px;
    margin-right: 2px;
    cursor: pointer;
    
    width: 50px;
    height: 70px;
    border: 1px solid #a6a6a4;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .border {
    height: 5px;
    background-color: lightgrey;
    position: relative;
  }
  
  .insideBorder {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 5px solid white;
      border-right: 5px solid transparent;
      height: 0;
      width: 30px;
  }
  
  .main {
    padding: 2px 4px 2px 4px;
    max-width: 100%;
    max-height: 100%;
  }
  
  .main__title h1 {
    font-size: 0.7rem;
    overflow-wrap: break-word;
  }
  
  .main__title svg {
    fill: black;
    margin: 0px;
    width: 10px;
  }
  
  .main__content {
    display: flex;
    flex-wrap: wrap;
  }
  
  .main__content p {
    font-size: 0.6rem;
    overflow-wrap: break-word;
  }
  
  
  
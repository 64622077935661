.container {
    padding: 1rem 2rem;
    width: 100%; /* Adjust as needed */
    /* height: 100vh; Adjust as needed */
    /* height: 82vh;
    max-height: 82vh;
    overflow-x: hidden;
    overflow-y: auto; */
    position: relative; /* Needed for absolute positioning of header and body */
}

.sidebarOpen {
  margin-left: 240px;
  width: calc(100% - (240px / var(--viewport-width)));
}

.header {
    /* padding: 10px; Adjust as needed */
    position: sticky; /* Make the header sticky */
    top: 0; /* Stick to the top of the container */
    z-index: 1; /* Ensure the header is above the body */
  }

.body {
    overflow-y: auto; /* Enable vertical scrolling */
    height: 73vh;
    max-height: 73vh;
    overflow-x: hidden;
  /* height: calc(100% - 40px); Adjust based on header height and padding */
  /* padding: 10px; Adjust as needed */
}

.body::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.body::-webkit-scrollbar-thumb {
border-radius: 20px;
background-color: #c4c4c4;
}

.body::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}


.activeFilterIcon > svg {
  margin-left: 4px;
  color: #42E691;
  width: 18px;
}
.fab {
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
}

@media only screen and (max-width: 720px) {
  .activeFilterIcon > svg {
      margin-right: 2px;
    }

  .container {
    width: 200vw;
}
  
  .scrollList {
      width: 100%;
      overflow-x: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}
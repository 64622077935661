.container {
    position: sticky;
    position: -webkit-sticky; /* Safari */
    width: 100%;
    max-width: 100vw;
    z-index: 3;
    top: 0px;
    left: 0;
    transition: margin-left 0.3s ease;
}

.sidebarOpenNotKanban {
    margin-left: 240px;
    width: calc(100% - 240px);
}

.sidebarOpenKanban {
    margin-left: 240px;
    width: calc(100vw - 240px);
    position: fixed;
}

.drawerIcon {
    margin-right: 10px;
    cursor: pointer;
    margin-left: -3px;
  }

.topNavBar {
    display: flex;
    justify-content: space-between;
    height: 34px;
    background-color: #ffffff;
    padding-left: 2rem;
    padding-right: 2rem;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    /* box-shadow: 0 1px 4px #d6d6d6; */
    border-bottom: 1px solid #d6d6d6;
    align-items: center;
}

.disableBottomBorder {
    border-bottom: none;
}

.title {
    display: flex;
    align-items: center;
    width: 0%;
    margin-left: 10px;
    justify-content: flex-end;
    height: 34px;
}

.title__settings {
    margin-right: 14px;
}

.title__settings > svg {
    color: #B5B5B5;
    width: 24px;
}

.title__text {
    margin-right: 14px;
    max-width: 80%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-y: none;
    cursor: default;
}

.title__text::-webkit-scrollbar {
    display: none;
}

.breadcrumbs {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-right: 10px;
    overflow-x: scroll;
    height: 34px;
    /* Disable scrollbar */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Disable Scrollbar */
.breadcrumbs::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .sidebarOpen {
        margin-left: 50px;
        width: calc(100% - 50px);
    }
}

@media only screen and (max-width: 720px) {
    .title__settings {
        margin-right: 6px;
    }

    .topNavBar {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    .sidebarOpen {
        margin-left: 50px;
        width: calc(100% - 50px);
    }
}


.container {
    /* white */
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    margin-top: 1rem;
    /* width: 952px; */
}

.tabs {
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.columnInvoice {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    height: 53px;
    /* width: 200vw; */
    border-top: 1px solid #ececec;
    /* border-bottom: 1px solid #ececec; */
}

.columnInvoice__invNumber {
    /* width: 60px; */
    width: 7%;
    margin-right: 0.7rem;
}

.columnInvoice__status {
    /* width: 60px; */
    width: 6%;
    margin-right: 1.1rem;
}

.columnInvoice__expDate {
    /* width: 1200px; */
    width: 12%;
    margin-right: 0.8rem;
}


.columnInvoice__description {
    /* width: 320px; */
    width: 20%;
    margin-right: 1.3rem;
}

.columnInvoice__discAmount {
    /* width: 100px; */
    width: 10%;
    margin-right: 4px;
}

.columnInvoice__amount {
    /* width: 100px; */
    width: 10%;
    margin-right: 4px;
}

.columnInvoice__action {
    /* width: 100px; */
    width: 10%;
    margin-right: 4px;
}

.columnInvoice__subscriptionDate {
    width: 160px;
    margin-right: 4px;
}

.listInvoices {
    max-height: 238px;
    overflow-y: auto;
    min-height: 238px;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
    /* width: 200vw; */
}

.listInvoices::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.listInvoices::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.listInvoices::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}


@media only screen and (max-width: 1024px) {
    
  }
  
@media only screen and (max-width: 720px) {
.listInvoices {
    width: 200vw;
}

.columnInvoice {
    width: 200vw;
}

.scrollListInvoice {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.tabs {
    width: 120vw;
}

.scrollTabs {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
}
.container {
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.tabs {
    display: flex;
    align-items: center;
    padding-left: 0.3rem;
}

@media only screen and (max-width: 1024px) {
 
}

@media only screen and (max-width: 720px) {

    .container {
        padding-right: 0px;
    }

    .tabs {
        width: 120vw;
    }
    
    .scrollTabs {
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
.container {
    position: sticky;
    position: -webkit-sticky; /* Safari */
    width: 100%;
    max-width: 100vw;
    z-index: 2;
    top: 73px;
    left: 0;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    padding-bottom: 2px;
}

.sidebarOpen {
    margin-left: 110px;
}

.isOver {
    background-color: #FF7171
}

.isSoon {
    background-color: #FDC532
}

.cta {
    margin-left: 3px;
}
.container {
    padding: 0.5rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  
  .ManageMoveCard__Header {
      display: flex;
      align-items: center;
      
  }
    
  .ManageMoveCard__Header h1 {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
    
  .ManageMoveCard__Header svg {
    cursor: pointer;
  }
   
  .ManageMoveCard__smallTitle {
    color: #263238;
    font-size: 0.8rem;
  }
    
  .body {
    /* display: flex; */
    margin-top: 10px;
  }
  
  .formSection a {
    font-size: 0.8rem;
  }
  
  .formSection p {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .formArea {
    margin-top: 5px;
  }
  
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
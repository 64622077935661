.ToggleLabelsContainer__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ToggleLabelsContainer__Header h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.ToggleLabelsContainer__Header svg {
  cursor: pointer;
}

.ToggleLabelsContainer__smallTitle {
  color: #263238;
  font-size: 0.8rem;
}

.sectionSmallTitle {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ToggleLabelsContainer__body {
  margin-top: 10px;
  max-height: 25vh;
  overflow-y: auto;
}

.ToggleLabelsContainer__teamMember {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ToggleLabelsContainer__teamMember:hover {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.ToggleLabelsContainer__editIcon {
  cursor: pointer;
}

.ToggleLabelsContainer__editIcon:hover {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.ToggleLabelsContainer__checkIcon {
  background-color: #2196f3;
  border-radius: 30%;
  color: white;
  cursor: pointer;
}

.sectionLabel {
  display: flex;
  align-items: center;
}
.sectionLabelBody {
  flex-grow: 2;
  padding-right: 10px;
}

.sectionLabelEdit {

}

.sectionFooter {
  margin-top: 15px;
}


.plusButton {
  width: 24px;
  height: 24px;
  border: 1px solid #DEEAFF;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plusButton > svg {
  width: 16px;
  color: #708FC7;
}

.plusButton:hover {
  background: #DEEAFF;
}
.container {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 1rem;
}

.listCompanies {
    height: 30vh;
    max-height: 30vh;
    overflow-y: auto;
}

.listCompanies::-webkit-scrollbar {
  width: 7px;
}

.listCompanies::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}

.listCompanies::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.leftSection {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftSection > img {
  width: 400px;
}

.rightSection {
  width: 50%;
  padding-right: 3rem;
  padding-left: 0.3rem;
}

.logoutIcon {
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
}

.textSection {
  padding-top: 20px;
  margin-bottom: 20px;
}


  
@media only screen and (max-width: 1024px) {
  .container {
    padding-bottom: 2rem;
}
  .leftSection > img {
    width: 370px;
  }
}

@media only screen and (max-width: 720px) {
  .container {
      width: auto;
      display: block;
      padding-bottom: 2rem;
  }

  .rightSection {
    width: 100%;
  }

  .leftSection {
    width: 100%;
  }

  .leftSection > img {
    width: 240px;
  }

  .rightSection {
    margin-top: -2.5rem;
    padding-right: 1.2rem;
    padding-left: 1.2rem;
  }

  .listCompanies {
    height: 30vh;
    max-height: 30vh;
}

}
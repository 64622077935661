.container {
    display: flex;
    align-items: center;
    height: 50vh;
}

.title {
    margin-bottom: 1rem;
}

.description {
    margin-bottom: 1.2rem;
}

.illustration {
    width: 400px;
}

.illustration > img {
    width: 400px;
}


@media only screen and (max-width: 1024px) {
    .illustration > img {
        width: 300px;
    }
}
  
@media only screen and (max-width: 720px) {
    .container {
        width: auto;
        display: block;
        padding-bottom: 2rem;
    }  

    .illustration {
        display: flex;
        justify-content: center;
        width: auto;
    }
    .illustration > img {
        width: 250px;
    }
    
    .title {
        display: flex;
        justify-content: center;
    }
    
    .description {
        display: flex;
        justify-content: center;
    }

    .cta {
        display: flex;
        justify-content: center;
    }
}
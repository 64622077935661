.container {
  padding: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.ManageMoveCard__Header {
    display: flex;
    align-items: center;
    
}
  
.ManageMoveCard__Header h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}
  
.ManageMoveCard__Header svg {
  cursor: pointer;
}
 
.ManageMoveCard__smallTitle {
  color: #263238;
  font-size: 0.8rem;
}
  
.body {
  /* display: flex; */
  margin-top: 10px;
}
  

.listSection {
  display: flex;
}

.listSection a {
  font-size: 0.8rem;
}

.listSection p {
  font-size: 0.9rem;
  font-weight: 600;
}

.listSection__listName {
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 5px;
  margin-right: 5px;
  width: 70%;
  cursor: pointer;
  position: relative;
}

.listSection__listPosition {
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 5px;
  width: 30%;
  cursor: pointer;
  
}

.listSection__listName:hover {
  background-color: lightgray;
}

.listSection__listPosition:hover {
  background-color: lightgray;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container {
    height: 16px;
    background: #FFEEC3;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
}
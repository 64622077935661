.sectionTitleLogo {
    display: flex;
    width: 100%;
    padding-top: 10vh;
}

.sectionCompanyInfo {
    width: 60%;
    padding: 1vh 18vw 0px 0px;
}

.sectionCompanyInfoSidebarOpen {
    padding-right: 4vw;
}

.sectionCompanyLogo {
    width: 40%;
    padding: 1vh 3vw 0px 15vw;
    display: flex;
    justify-content: flex-end;
}

.sectionCompanyLogoSidebarOpen {
    padding-left: 0vw;
}

.sidebarOpen {
    margin-left: 240px;
    padding-left: 3rem;
    padding-right: 9rem;
}

.logo {
    display: inline-block;
    width: 200px;
    border-radius: 10px;
}

.companyTitle {
    margin-bottom: 10px;
}

.buttonSection {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}

@media only screen and (max-width: 1024px) {
    .sectionTitleLogo {
        
    }
}

@media only screen and (max-width: 720px) {
    .sectionTitleLogo {
        display: block;
        width: auto;
    }

    .sectionCompanyInfo {
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .sectionCompanyLogo {
        width: 100%;
        justify-content: center;
        padding: 0px;
        margin-bottom: 1rem;
    }
}
.content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.visitAcquisitionFactory {
    display: flex;
    align-items: center;
}

.activationRevenueIllustration {
    display: flex;
    align-items: center; 
}

.illustration {
    margin-left: 1rem;
    margin-right: 1rem;
}

.retention {
    margin-bottom: 1.3rem;
}

.referral {
    margin-top: 1.3rem;
}

.filter {
    position: absolute;
    max-width: 560px;
    top: 1.5rem;
    right: 1.5rem;
}

.revenueAndChurn {
    padding-top: 8.5rem;
}

.churn {
    margin-top: 1.3rem;
}

.revenueIllustration > img {
    max-width: 150px !important;
    max-height: 120px !important;
}
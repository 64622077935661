.iconSection__item {
    display: flex;
    cursor: pointer;
  }  

.iconLogout {
    position: relative;
  }
  
  .iconLogoutDisableClick {
    position: relative;
    cursor: default;
  }
  
  .logoutLoadingOverlay {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: white;
    opacity: 0.7;
    top: 0px;
    left: 0px;
  }
  
  .logoutLoadingSpinner {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
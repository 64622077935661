.searchBox {
    margin-bottom: 5px;
}

.menu {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 3px;
    cursor: pointer;
  }
  
  .menu:hover {
    background-color: white;
    border-radius: 3px;
  }
  
  .menu p {
    margin: 0;
    font-size: 0.8rem;
  }
  
  .menu img {
    margin: 0;
  }
  
  .titleBox {
    margin-bottom: 5px;
  }
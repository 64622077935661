.container {
    position: relative;
}
.welcomeText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.widget {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.item {
    width: 35vw;
    margin-top: 1rem;
}

.itemSidebarOpen {
    width: 37vw;
}

.openWidgetIcon {
    position: absolute;
    top: 9px;
    right: -30px;
    cursor: pointer;
}

.openWidgetIcon > svg {
    width: 24px;
}

.openWidgetIcon:hover {
    opacity: 0.8;
}

.openWidgetIconWithText {
    display: flex;
    align-items: center;
}

.openWidgetIconWithText > svg {
    width: 24px;
    margin-right: 5px;
}

@media only screen and (max-width: 720px) {
    .widget {
        display: block;
      }

      .item {
        width: 91vw;
      }
      .itemSidebarOpen {
        width: 91vw;
    }

  }
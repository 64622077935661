.container {
    border: 1px solid #ECECEC;
    border-radius: 10px;
    /* min-width: 310px; */
    width: 100%;
    /* height: 100%; */
    min-height: 175px;
    background-color: white;
    position: relative;
    padding: 1rem;
}

.lowHeight {
    min-height: 110px;
}
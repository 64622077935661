/* with overflow vertical bug no nested scrolling supported */
.ListContainer__listCardsSection {
  overflow-y: auto;
  max-height: 65vh;
  margin-right: 10px;
  position: relative;
}

.ListContainer__listCardsSection::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.ListContainer__listCardsSection::-webkit-scrollbar-thumb {
border-radius: 20px;
background-color: #c4c4c4;
}

.ListContainer__listCardsSection::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.ListDroppable {
  min-height: 10vh;
}

.card {
  margin-bottom: 6px;
}
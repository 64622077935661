.container {
    display: flex;
    align-items: center;
    padding: 0.3rem;
    border-radius: 5px;
    height: 22px;
}

.completed {
    background-color: #42E691;
}

.icon {
    margin-right: 4px;
}

.icon > svg {
    width: 16px;
}

.completedIcon > svg {
    color: white;
}
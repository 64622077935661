.container {
    max-height: 120px;
    overflow-y: auto;
    min-height: 40px;
    padding: 0px 0px 12px 0px;
}

.container::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.container::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.companySection {
    /* margin-bottom: 8px; */
    margin-top: 7px;
    justify-content: space-between;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
}

.title {
    margin-bottom: 2px;
}

.text > svg {
    width: 16px;
    color: #FDC532;
}

.text:hover {
    opacity: 0.8;
}

.counter {
    margin-right: 10px;
}

.desc {
    margin-bottom: 4px;
}
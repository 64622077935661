.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionsSection {
  /* height: 69vh; */
  display: block;
  position: relative;
  padding: 1.2rem 1.2rem;
}

.infiniteScroll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}

.questionItem {
  margin-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
}
.container {
    width: 100%;
    background-color: white;
    height: 100%;
}

.list {
    max-height: 45vh;
    overflow-y: auto;
    height: 45vh;
    /* padding: 0px 0px 12px 0px; */
    padding: 0.5rem;
    overflow-x: hidden;
    /* width: 100%; */
    /* background-color: white; */
}


.list::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.list::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

@media only screen and (max-width: 720px) {
    .container {
        width: unset;
    }
}
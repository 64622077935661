.Card {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;
  padding: 20px;
  padding-right: 28px;
  width: 230px;
  height: 180px;
  margin: 10px;
  margin-right: 10px;
  display: inline-block;
  text-align: left;
  cursor: pointer;
  position: relative;
  border: 1px solid #fafafa;
  border-bottom: 0px;
}

.Card__absolute__topRight {
  position: absolute;
  top: 0;
  right: 0;
}

.text {
  margin-top: 0px;
}

.cardTitle {
  margin-bottom: 8px;
}

/* .text > h1 {
  font-weight: 800;
font-size: 22px;
line-height: 30px;
  color: #fff;
}
.text > p {
  color: #fff;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
} */

.Card button {
  position: relative;
  float: right;
  margin-top: 20px;
}

.members {
  position: absolute;
  bottom: 15px;
}

.content {
  width: 100%;
  height: 100%;
}

.action {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 22px;
  height: 22px;
  background-color: #fafafa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action > div > svg {
  width: 18px;
}


.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.teamColorBar {
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0px;
  left: 0px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  /* border-bottom: 1px solid #fafafa; */
}



@media only screen and (max-width: 1024px) {
  .Card {
    width: 200px;
    height: 255px;
    margin: 8px;
    margin-right: 8px;
  }

  .text {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 720px) {
  .Card {
    width: 155px;
    height: 200px;
    margin: 5px;
    margin-right: 5px;
  }

  .text {
    margin-top: 10px;
  }
}
.container {
  padding: 2rem 2.5rem;
}

.menuSection {
  position: relative;
}

.headerSection {
  display: flex;
  
  justify-content: space-between;
}
.titleSection__title {
  display: flex;
}

.boxDateContainer {
  display: flex;
  align-items: center;
}

.boxDateContainer > span {
  margin-right: 5px;
}

.boxDate {
  height: 60px;
  width: 60px;
  background-color: #0f3057;
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.boxDate p {
  color: white;
  margin: 0;
  font-size: 0.6rem;
}

.boxDate svg {
  fill: white;
  margin-bottom: 5px;
}

.titleSection h1 {
  font-size: 2rem;
  margin: 0;
}

.titleSection__creator {
  display: flex;
  margin-top: 10px;
}

.titleSection__creator__photo {
  margin-right: 7px;
}

.titleSection__creator__photo img {
  margin: 0;
}

.titleSection__creator__body h1 {
  font-size: 1.1rem;
  margin: 0;
}

.titleSection svg {
  cursor: pointer;
}

.emojiPicker {
  position: absolute;
  top: 0;
  right: 0;
}

.questionsSection {
  min-height: 54vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 0;
}

.commentSection {

}

.titleSection__detail h1 {
  margin: 0;
}

.titleSection__detail p {
  margin: 0;
}

.titleSection__detail img {
  margin-bottom: 0;
}

.detail__menu {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.detail__menu__content__subscriber {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.detail__menu__title {
  width: 70px;
}

.detail__menu__title > p {
  font-weight: bold;
  margin: 0;
}

.detail__menu__content {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
}

.detail__menu__content p {
  margin: 0;
  margin-right: 20px;
}

.addToCalendar {
  font-size: 0.9rem;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.addToCalendar:hover {
  color: #007bff;
  text-decoration: none;
}

.subscribers {
  display: flex;
  align-items: center;
  margin-right: 10px;
}


.archivedSection {
  display: flex;
  background-color: #fafcc2;
  align-items: center;
  padding: 6px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.archivedSection svg {
  margin-right: 6px;
}

.archivedSection h1 {
  font-size: 1.2rem;
  margin: 0;
}

.cheersSection {
  margin-top: 15px;
  margin-bottom: 30px;
}

.titleAndCreator {
  margin-left: 10px;
}
.titleAndCreator > span {
  font-size: 0.9rem;
}


@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .detail__menu {
    display: block;
  }

}

.seenSection {
  margin-bottom: 5px;
  padding-left: 5px;
}

.typingSection {
  min-height: 24px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 10px;
}
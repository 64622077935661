.DescIcon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}

.DescIcon svg {
  width: 15px;
}

.container {
}
.permission {
    padding: 1.5rem;
    border-radius: 5px;
    border: 1px solid #FFE49B;
    min-height: 220px;
    min-width: 50%;
    margin-right: 0.7rem;
}

.permission:last-child {
    margin-right: 0px;
}

.horizontal {
    display: flex;
    align-items: center;
}

.verticalPermission {
    margin-right: 0px;
    margin-bottom: 0.5rem;
}

.verticalPermission:last-child {
    margin-right: 0px;
    margin-bottom: 0px;
}

.permissionItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.6rem;
}

.icon {
    margin-right: 5px;
    margin-top: -0.2rem;
}

.title {
    margin-bottom: 0.6rem;
}

@media only screen and (max-width: 1024px) {
    
}
  
@media only screen and (max-width: 720px) {
    .horizontal {
        display: block;
    }

    .horizontalPermission {
        margin-right: 0px;
        margin-bottom: 0.5rem;
    }
}
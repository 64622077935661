.container {
  padding: 2rem 2.5rem;
}

.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputTitleSection {
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
  position: relative;
}

.inputTitleSection textarea {
  width: 100%;
  margin-right: 5px;
  position: relative;
  outline: 0;
  border: 0;
  width: 100%;
  resize: none;
  font-size: 22px;
  font-weight: bold;
}

.inputTitleSection svg {
  cursor: pointer;
}

.emojiPicker {
  position: absolute;
  top: 2rem;
  right: 5px;
  z-index: 4;
}

.postsSection {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
}

.Subscribers {
  margin-bottom: 20px;
  position: relative;
}

.subscribersHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}

.subscribersHeader h1 {
  margin: 0;
  margin-right: 10px;
  font-size: 1rem;
}

.subscribersItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subscribersItem span {
  margin-right: 5px;
  font-size: 0.8rem;
}

.plusButton {
  background-color: lightgrey;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plusButton:hover {
  opacity: 0.8;
}


.actionSection {
  margin-top: 7px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.actionSection__cancel {
  margin-right: 5px;
}

.emojiButton {
  display: flex;
  justify-content: flex-end;
  position: relative;
}


/* targetting emoji pop up  */
.emojiButton > div > div {
  top: 0;
  right: 2vw;
}

@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .emojiButton > div > div {
    top: 0;
    left: 0;
    right: 0;
  }

  .inputTitleSection textarea {
    font-size: 1.5rem;
  }
}

.selectDueDateOption__chooseOption {
  margin-top: 0px;
  display: flex;
  align-items: center;
  height: 62px;
}

.selectDueDateOption__item__text {
  margin-right: 10px;
}

.dueDate {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dueDate__titleMenu {
  width: 100px;
}

.dueDate__titleMenu > p {
  font-weight: bold;
}

.selectDueDateOption__title {
  display: flex;
  align-items: center;
}

.selectDueDateOption h1 {
  margin: 0;
  font-size: 1rem;
  margin-right: 10px;
}

.selectDueDateOption__item {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.selectDueDateOption__item > p {
  font-size: 0.8rem;
  margin-right: 10px;
}

.datePicker {
  margin-right: 10px;
}

.manageSubscribers {
  margin-top: 5px;
  margin-bottom: 20px;
}
.container {
    height: 65px;
    width: 120px;
    border-radius: 10px;
    border: 1px solid #D6D6D6;
    padding: 5px;
}

.text {
    display: flex;
    justify-content: center;
}

.emptyText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.percentage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ratingStart {
    margin-right: 0.2rem;
}

.good {
    border: 1px solid #42E691;
}

.bad {
    border: 1px solid #ffbebe;
}

.normal {
    border: 1px solid #D6D6D6;
}
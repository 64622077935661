.container {
}

.chartSection {
    display: flex;
    flex-wrap: wrap;
}

.chartBox {
    margin-right: 1.2rem;
    margin-bottom: 1.2rem;
}

.filter {
    position: absolute;
    max-width: 760px;
    top: 1.5rem;
    right: 1.5rem;
}

.charttitle {
    margin-bottom: 0.5rem;
}
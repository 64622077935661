.body {
    display: flex;
    align-items: center;
}

.form {
    width: 300px;
    margin-right: 1rem;
}

.action {
    display: flex;
    align-items: center;
}

.actionButton {
    margin-right: 0.2rem;
}

.actionButton:last-child {
    margin-right: 0px;
} 

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user {
    display: flex;
    align-items: center;
}

.photo {
    margin-left: 1rem;
}
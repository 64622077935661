.container {
    position: relative;
    width: 100%;
    padding-top: 0.3rem;
}

.description {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.descriptionItem {
    margin-right: 0.5rem;
}

.backButton {
    position: absolute;
    top: -1.7rem;
    left: -0.4rem;
    cursor: pointer;
}

.columnTitle {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.listRole {
    max-height: 250px;
    overflow-y: auto;
    min-height: 40px;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
    /* width: 200vw; */
}

.listRole::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.listRole::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.listRole::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}


@media only screen and (max-width: 720px) {
    .listRole {
        width: 175vw;
    }
    .item {
        width: 175vw;
    }
    .columnTitle {
        width: 175vw;
    }

    .scrollListItems {
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
.container {
    cursor: pointer;
}

.container > svg {
    color: #7A7A7A;
    width: 24px;
}

.container:hover {
    opacity: 0.8;
}

.colorVariantLight > svg {
    color: white;
    width: 24px;
}

.colorVariantDark > svg {
    color: #7A7A7A;
    width: 24px;
}
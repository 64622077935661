.container {
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 40px;
}

.buttonWrapper {
    position: absolute;
    top: 8px;
    right: 8px;
}

.button {
    position: relative;
}

.title {
    display: flex;
    align-items: center;
}

.icon {
    margin-left: 0.3rem;
}

.icon > svg {
    width: 16px;
    color: #FDC532;
}
.container {
    display: flex;
    align-items: center;
}

.columnTitle_badge {
    width: 30%;
    margin-right: 1rem;
}

.columnTitle_permissionInfo {
    width: 68%;
}

@media only screen and (max-width: 720px) {
    .columnTitle_badge {
        width: 22%;
        margin-right: 1rem;
    }
    
    .columnTitle_permissionInfo {
        width: 60%;
    }
}
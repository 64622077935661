.fc-toolbar-title {
    font-size: 20px !important; /* Adjust the font size as needed */
}

.fc-button {
    font-size: 0.7em !important; /* Adjust the font size as needed */
}

.fc-button-primary {
    /* background-color: #FDC532 !important;
    border-color: #FDC532 !important; */
}
.fc-h-event {
    color: black !important;
}

.fc-event-main {
    color: black !important;
}
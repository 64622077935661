.container {
    min-width: 130px;
    width: 130px;
    height: 24px;
    background: #F0B418;
    box-shadow: 1px 2px 8px rgba(151, 151, 151, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 14px;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}

.container:hover {
    opacity: 0.8;
}

.icon {
    width: 21px;
    height: 21px;
    background: #FDC532;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon > svg {
    color: white;
    width: 17px;
}

@media only screen and (max-width: 720px) {
    .container {
        /* min-width: 100px;
        width: 100px; */
        /* padding-left: 9px; */
    }
}
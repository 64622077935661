
.container {
    border-radius: 100%;
    border: 1px solid white;
}

.online {
    background-color: #42E691;
}

.idle {
    background-color: #FFE49B;
}

.offline {
    background-color: #B5B5B5;
}

.xs {
    width: 8px;
    height: 8px;
}

.sm {
    width: 10px;
    height: 10px;
}

.md {
    width: 12px;
    height: 12px;
}

.lg {
    width: 13px;
    height: 13px;
}

.xl {
    width: 14px;
    height: 14px;
}

.xxl {
    width: 14px;
    height: 14px;
}

.super {
    width: 20px;
    height: 20px;
}
.container {
    display: flex;
    align-items: flex-start;
}

.item {
    margin-right: 1rem;
    width: 40%;
}

.item:last-child {
    margin-right: 0px;
}

.skeleton {
    margin-bottom: 1rem;
}
.container {
    width: 100%;
}

.chart {
    width: 32vw;
    margin-bottom: 1rem;
}

.chartSidebarOpen {
    width: 25vw;
}

.title {
    margin-bottom: 1rem;
}

.interpretation {
    display: flex;
}

.interpretationItem {
    margin-right: 0.5rem;
    flex-grow: 1;
}

.interpretationItem:last-child {
    margin-right: 0px;
}

@media only screen and (max-width: 1024px) {
    .container {
        margin-bottom: 1rem;
    }
    .chart {
        width: 50vw;
    }
}

@media only screen and (max-width: 720px) {
    .container {
        margin-bottom: 1rem;
    }

    .chart {
        width: 80vw;
    }
}
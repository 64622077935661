.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 0; 
}

.sidebarOpen {
  width: calc(100% - (240px / var(--viewport-width)));
  margin-left: 240px;
}
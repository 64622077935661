.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 2rem 2.5rem;
}

.skeleton {
    margin-bottom: 1rem;
    margin-right: 1rem;
}
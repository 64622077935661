
.icon > svg {
    color: #7A7A7A;
}

.icon {
    margin-right: 0.9rem;
}

.iconTrue > svg {
    color: #708FC7;
}

.iconTrue {
    margin-right: 0.9rem;
}
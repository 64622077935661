.LogoHeader {
    z-index: 4;
    position: absolute;
    top: 4vh;
    left: 3vw;
    cursor: pointer;
}

.LogoHeaderMobile {
    width: 80px;
    margin-left: 0.5rem;
}

.logoutIcon {
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 4vh;
    right: 3vw;
}

.logoutIconMobile {
    margin-right: 0.5rem;
}
.CreateLabelContainer__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CreateLabelContainer__Header h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.CreateLabelContainer__Header svg {
  cursor: pointer;
}

.CreateLabelContainer__smallTitle {
  color: #263238;
  font-size: 0.8rem;
}

.sectionSmallTitle {
  margin-top: 10px;
}

.CreateLabelContainer__body {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.CreateLabelContainer__teamMember {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.CreateLabelContainer__teamMember:hover {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.CreateLabelContainer__editIcon {
  cursor: pointer;
}

.CreateLabelContainer__editIcon:hover {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.CreateLabelContainer__checkIcon {
  background-color: #2196f3;
  border-radius: 30%;
  color: white;
  cursor: pointer;
}

.sectionLabel {
  display: flex;
  align-items: center;
}
.sectionLabelBody {
  flex-grow: 2;
  padding-right: 10px;
}

.sectionLabelEdit {

}

.sectionFooter {
  margin-top: 25px;
}

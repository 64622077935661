.container {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #D6D6D6;
    padding: 1.5rem;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 78vh;
    margin-bottom: 1.3rem;
}

.roleBadge {
    width: 28%;
}

.rolePermissionInfo {
    width: 68%;
}

.divider {
    height: 100%
}


@media only screen and (max-width: 720px) {
    .roleBadge {
        width: 20%;
    }
    
    .rolePermissionInfo {
        width: 76%;
    }
}
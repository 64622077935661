.header {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.editIcon {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  background-color: lightgrey;
  cursor: pointer;
}

.editIcon svg {
  width: 15px;
}

.mediumTitle {
  color: #263238;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.sectionEmptyDesc {
  background-color: #ebecf1;
  padding: 7px;
  min-height: 80px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.sectionEmptyDesc:hover {
  opacity: 0.8;
}

.sectionActionInputForm {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.sectionActionInputForm svg {
  width: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.sectionDesc {
  min-height: 40px;
  
}

.actionSection {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.actionSection svg {
  margin-left: 7px;
  cursor: pointer;
  width: 20px;
}

/* targetting emoji pop up  */
.emojiButton > div > div {
  top: 0;
  right: 2vw;
}

@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .emojiButton > div > div {
    top: 0;
    left: 0;
    right: 0;
  }
}
.container {
  padding: 2rem 2.5rem;
}

.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.headerSection h1 {
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .headerSection h1 {
    font-size: 1.5rem;
  }
}

.creator {
  margin-bottom: 1rem;
}

.headerSection__menu {
  position: relative;
}

.roundMenuSection {
  position: relative;
}

.docsSection {
  min-height: 69vh;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}


.archivedSection {
  display: flex;
  background-color: #fafcc2;
  align-items: center;
  padding: 6px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.archivedSection svg {
  margin-right: 6px;
}

.archivedSection h1 {
  font-size: 1.2rem;
  margin: 0;
}


/* overlay section drop zone */
.container {
  position: relative;
}

.box {
  width: 100%;
  height: 100%;
}

.backgroundOverlay {
  z-index: 9;
  background-color: white;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.contentOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}.Subscribers {
  margin-bottom: 20px;
  position: relative;
}

.subscribersHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}

.subscribersHeader h1 {
  margin: 0;
  margin-right: 10px;
}

.subscribersItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subscribersItem span {
  margin-right: 5px;
}

.cheersSection {
  margin-top: 15px;
  margin-bottom: 30px;
}


.activeFilterIcon > svg {
  margin-left: 4px;
  color: #42E691;
  width: 18px;
}

@media only screen and (max-width: 720px) {
  .activeFilterIcon > svg {
      margin-right: 2px;
    }
}
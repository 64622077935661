.container {
  height: calc(100% - 33px - 40px - 33px);
  width: 350px;
  position: fixed;
  top: 104px;
  left: 0;
  background-color: #e3e3e3;
  padding: 15px;
  box-shadow: 0 2px 5px #ccc;
  z-index: 2;
  padding-top: 22px;
}

.sidebarOpen {
  left: 240px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header h1 {
  margin: 0;
}

.header svg {
  cursor: pointer;
}

.body {
  margin-top: 20px;
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 10px;
}

.menu {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 3px;
  cursor: pointer;
}

.menu:hover {
  background-color: white;
  border-radius: 3px;
}

.menu p {
  margin: 0;
  font-size: 0.8rem;
}

.menu img {
  margin: 0;
}

.icon {
  margin-right: 7px;
}

.searchSection > p {
  font-size: 0.8rem;
  margin-top: 5px;
  margin-left: 6px;
  margin-bottom: 20px;
}

.labelSection {
  margin-bottom: 20px;
}

.memberSection {
  margin-bottom: 20px;
}

.dueDateSection {
  margin-bottom: 20px;
}

.label {
  margin-right: 7px;
  width: 25px;
  height: 25px;
  border-radius: 3px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text svg {
  width: 15px;
}

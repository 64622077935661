.container {
  margin-bottom: 10px;
  margin-right: 20px;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  width: 260px;
  height: 242px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 4px 8px 4px 8px;
  position: relative;
}

.icon {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.icon > img {
  width: 130px;
  margin-bottom: 20px;
}

.headline {
  margin-top: 15px;
}

.body {
  margin-top: 10px;
  background: #F0F1F7;
  border-radius: 10px;
  width: 100%;
  height: 186px;
  padding: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 720px) {
  .container {
    margin-right: 0;
  }
}
.container {
  margin-bottom: 20px;
  position: relative;
}

.container p {
  font-size: 0.7rem;
    margin-bottom: 0;
    text-decoration: none;
}

/* .headerSection {
  display: inline-flex;
  margin-left: auto;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2;
  padding: 2px;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 5px; 
}
  
  .headerSection svg {
    cursor: pointer;
  }
  
  .headerSection h1 {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  
  .bodySection {
    margin-top: 5px;
  }
  
  .bodySection p {
    font-size: 0.9rem;
    margin-bottom: 0;
    text-decoration: none;
  } */
  
  .menu {
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 6px 4px 6px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    background-color: #212529;
    border-radius: 5px;
  }

  .menu > p {
    color: white;
  }
  
  .menu:hover {
    opacity: 0.8;
  }
  
  .actionSection {
    margin-top: 20px
  }
  
  
  .icon {
    width: 15px !important;
    color: white;
    margin-right: 4px;
  }
  
  .iconUnset {
    width: 15px !important;
    margin-left: 7px;
  }

  .inline {
    display: inline-flex;
    position: relative;
  }
.body {
    overflow-y: auto;
    max-height: 200px;
}

.body::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Handle */
  .body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
  }
  
  .body::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
  }
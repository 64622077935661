.container {
    /* position: relative; */
}

.overlayBackground {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    opacity: 0.7;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
}

.overlayContent {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}


.dragStartTrue {
    opacity: 0.5;
}

.dragStartFalse {
    opacity: 1;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.iconAndTitle {
    display: flex;
    align-items: center;
}

.progressBar {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0.1rem;
}

.textProgressBar {
    /* margin-right: 0.1rem; */
    width: 40px;
}

.barProgressBar {
    width: 100%;
}

.addButton {
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    margin-left: 2.2rem;
}

.icon > svg {
    color: #7A7A7A;
}

.icon {
    margin-right: 0.9rem;
}

.action {
    display: flex;
    align-items: center;
}

.iconAction > svg {
    color: #7A7A7A;
    width: 22px;
}

.iconAction {
    cursor: pointer;
    margin-right: 2px;
}

.iconAction:hover {
    opacity: 0.8;
}

.iconAction:last-child {
    margin-right: 0px;
}
.ArchivedIcon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}

.ArchivedIcon svg {
  margin: 0;
  margin-right: 5px;
  width: 15px;
  height: auto;
}

.ArchivedIcon p {
  margin: 0;
  line-height: 1;
}

.select {
    max-height: 200px;
    overflow-y: auto;
    /* height: 200px; */
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
}

.select::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.select::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.select::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}
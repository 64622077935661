.container {
    min-height: 40px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    margin-bottom: 0.1rem;
}

.subTitle {
    display: flex;
    align-items: center;
}

.subItem {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.subItem:last-child {
    margin-right: 0px;
}

.icon {
    margin-right: 0.3rem;
}

.icon > svg {
    width: 20px;
    color: #7A7A7A;
}

@media only screen and (max-width: 720px) {
  .container {
    display: block;
    position: relative;
  }

  .export {
    position: absolute;
    bottom: 0.6rem;
    right: 1rem;
  }
}
.Question {
    display: block;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 3px 5px 3px 5px;
    border: 1px solid #a6a6a4;
    border-radius: 5px;
    text-align: left;
  }
  
  .mainContainer {
    display: flex;
    align-items: flex-start;
  }
  
  .photoSection {
    margin-right: 10px;
  }
  
  .photoSection img {
    margin: 0;
  }
  
  .contentSection__header {
    display: flex;
    align-items: center;
  }
  
  .contentSection__header__title {
    margin-right: 5px;
  }
  
  .contentSection__header__title h1 {
    font-size: 0.8rem;
    margin: 0;
  }
  
  
  .contentSection__content__creator {
    color: #0f4c75;
  }
  
  .contentSection p {
    font-size: 0.6rem;
  }

  .contentSection h1 {
    font-size: 0.8rem;
    margin: 0;
  }

  .contentSection img {
    margin-bottom: 0;
      width: 15px;
      height: 15px;
  }
  
  .contentSection svg {
    fill: black;
    margin: 0px;
    width: 15px;
  }
  
  @media only screen and (max-width: 600px) {
  
  }
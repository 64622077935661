.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
}

.bubble {
    margin-right: 5px;
}

.bubbleGif {
    width: 20px;
    margin-top: -2px;
}

.namesText {
    margin-right: 3px;
}

.text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: break-word;
    flex: 1;
}


.container {
    min-width: 28vw;
    margin-right: 1rem;
}

@media only screen and (max-width: 720px) {
    .container {
        width: 100%;
        margin-bottom: 1rem;
    }
}
.container {
    width: 100%;
    /* height: 65px; */
    background: #ffffff;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 5px;
    /* cursor: pointer; */
    padding: 5px 0.5rem 5px 0.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.container:hover {
    /* background: #FAFAFA; */
}

.photo {
    margin-right: 0.2rem;
}

.text {
    width: 100%;
    height: 100%;
    /* padding-top: 5px; */
}
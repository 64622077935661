.container {
    display: flex;
    align-items: center;
    /* padding-left: 1.5rem; */
    /* flex-wrap: wrap; */
}

.leftBox {
    width: 50%;
    margin-right: 0.5rem;
}

.rightBox {
    width: 50%;
}

.leftBox__price {
    margin-top: 2px;
}

.leftBox__button {
    margin-top: 0.8rem;
    display: flex;
    justify-content: start;
}

.rightBox__subTitle {
    margin-top: 6px;
}


@media only screen and (max-width: 1024px) {
    .container {
        
    }
}

@media only screen and (max-width: 720px) {
    .container {
        display: block;
        width: auto;
    }
    .leftBox {
        width: 100%;
    }
    
    .rightBox {
        width: 100%;
    }

}
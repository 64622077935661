.link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.container {
    padding: 0.3rem;
    border: 1px solid #cacaca;
    background-color: white;
    border-radius: 5px;
    width: 100%;
}

.container:hover {
    background-color: #FFEEC3;
}

  
.titleAndIcon {
    display: flex;
    align-items: flex-start;
  }

  .othersPropertiesSection {
    margin-top: 0.1rem;
    display: flex;
    flex-wrap: wrap;
  }

  .othersPropertiesSection p {
    font-size: 0.7rem;
      white-space: pre-wrap;
      word-wrap: break-word;
      margin-right: 2px;
  }

  .membersAndTeam {
    margin-top: 0.2rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .othersPropertiesSection > div {
    margin-right: 7px;
  }

  .teamAndList {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.3rem;
  }


  .labelsAndDate {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
.container {
  display: flex;
  align-items: center;
  margin-top: -13px;
  height: 40px;
}

.line {
  width: 95%;
}

.buttonContainer {
  width: 5%;
  margin-left: -5px;
}

.button {
  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.button > img {
  width: 59px;
}

.title {
  font-size: 0.8rem;
  font-weight: 500;
}

.formInput {
  margin-top: 5px;
}
.closeIcon {
  cursor: pointer;
}


@media only screen and (max-width: 720px) {
  .line {
    width: 90%;
  }
  
  .buttonContainer {
    width: 10%;
  }
}
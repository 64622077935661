.container {
    display: flex;
    align-items: center;
}

.icon {
    margin-left: 0.3rem;
}

.icon > svg {
    color: #7A7A7A;
}

.activityAndCheerButton {
    display: flex;
    align-items: center;
}

.activityButton {
    margin-right: 7px;
}
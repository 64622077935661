.wrapper {
  margin-right: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

}

.wrapper p {
  font-size: 0.6rem;
  text-decoration: underline;
  line-height: 1;
  margin: 0;
}

.wrapper span {
  font-size: 0.6rem;
  cursor: text;
}

.wrapper svg {
  width: 15px;
}

.container {
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    margin-top: 1rem;
}

.box {
    /* min-width: 400px; */
    width: 100%;
    height: 300px;
    background-color: white;
    border-radius: 4px 4px 0px 0px;
    margin-left: 1rem;
    padding: 1rem;
}

.box:first-child {
    margin-left: 0px;
}

.skeleton {
    margin-bottom: 1rem;
}
.container {
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
}

.body {
    height: 55vh;
}

@media only screen and (max-width: 720px) {
    
.body {
    height: 65vh;
}

  }
.logo {
  width: 200px;
  border-radius: 10px;
}

.loadingContainer {
  display: flex;

  align-items: center;
  justify-content: center;
  
  width: 100%;
  height: 100%;
}

/* overlay section drop zone */
.container {
  position: relative;
  border-radius: 10px;
  padding: 5px;
}

.box {
  width: 100%;
  height: 100%;
}

.backgroundOverlay {
  z-index: 9;
  border-radius: 10px;
  background-color: white;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.contentOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

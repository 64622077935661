.DueDate__smallTitle {
  color: #263238;
  font-size: 0.7rem;
}

.dueDateContainer {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 6px;
  background-color: #ebecf1;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.dueDateContainer p {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.color {
    margin-right: 0.6rem;
    margin-left: 0.2rem;
}

.color__icon {
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background-color: aquamarine;
}
.container {
    padding: 3rem;
}

@media only screen and (max-width: 1024px) {
    
}


@media only screen and (max-width: 720px) {
    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.label {
  display: inline-block;
  padding: 5px;
  min-height: 3px;
  min-width: 20px;
  border-radius: 2px;
  margin-right: 3px;
  cursor: pointer;
}

.label__block {
  min-height: 3px;
  min-width: 20px;
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
}
.box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    /* background: #FFEEC3; */
    /* border-radius: 10px; */
    height: 16px;
    width: 143px;
    /* padding: 2px; */
}

.containerLarge {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex: 1;
}

.container span {
    font-size: 0.8rem;
    color: grey;
}

.large span {
    font-size: 1.2rem;
    color: black;
}
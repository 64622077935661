.userAndBadge {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;
}

.user {
    width: 52%
}

.badge {
    margin-top: -0.8rem;
}

.teamAndPeriod {
    display: flex;
    justify-content: space-between;
}

.body {
    margin-top: 1rem;
}

.skeleton {
    margin-top: 0.5rem
}
.container {
    display: flex;
    padding: 1.5rem 3rem;
}

.listCompaniesSection {
    /* width:279px; */
    margin-right: 1rem;
    width: 22.3%;
    max-width: 22.3vw;
}

.billingSection {
    width: 74.8%;
    max-width: 74.8vw;
}

.sidebarOpen {
    margin-left: 240px;
}

.listCompaniesSectionSidebarOpen {
    
}

.billingSectionSidebarOpen {
    /* width: 60.8%;
    max-width: 60.8vw; */
}

/* @media only screen and (max-width: 830px) {
    .listCompaniesSection {
        width: 180px;
    }
} */


@media only screen and (max-width: 1024px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
        padding: 1.5rem 1.5rem;
    }

    .listCompaniesSection {
        /* width:279px; */
        width: 100%;
        max-width: 100vw;
        margin-top: 1.5rem;
    }
    
    .billingSection {
        width: 100%;
        max-width: 100vw;
    }
}

@media only screen and (max-width: 720px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
        padding: 1.5rem 1.5rem;
    }

    .listCompaniesSection {
        /* width:279px; */
        width: 100%;
        max-width: 100vw;
        margin-top: 1.5rem;
    }
    
    .billingSection {
        width: 100%;
        max-width: 100vw;
    }
}
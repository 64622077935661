.container {
    display: flex;
    align-items: center;
    width: 100%;
    /* flex-wrap: wrap; */
}

.block {
    margin-top: 1rem;
}

.block:last-child {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.chart {
    margin-right: 1rem;
    /* width: 30%; */
    width: 100%;
}

.chart:last-child {
    margin-right: 0rem;
}

@media only screen and (max-width: 1024px) {
  
}

@media only screen and (max-width: 720px) {
    .container {
        display: block;
      }
}


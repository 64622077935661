.header {
    margin-bottom: 20px;
}

.header__title {
    margin-bottom: 7px;
}

.formSection {
    margin-bottom: 20px;
}

.formSection__title {
    margin-bottom: 12px;
}

.buttonSection {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

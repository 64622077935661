.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 0.8rem;
    padding-bottom: 0.8rem; */
}

.title {
    margin-bottom: 1rem;
}

.text {
    margin-bottom: 3rem;
    padding-left: 6rem;
    padding-right: 6rem;
}

.formSection {
    height: 42vh;
    width: 80%;
    overflow-y: auto;
}

.formSection::-webkit-scrollbar {
    width: 7px;
}

/* Handle */
.formSection::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.formSection::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.form {
    margin-bottom: 20px;
}

.formText {
    margin-bottom: 10px;
}

.button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}


@media only screen and (max-width: 1024px) {
  .formSection {
    width: 90%;
  }

  .text {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media only screen and (max-width: 720px) {
  .formSection {
    width: 100%;
  }

  .text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.container {
    width: 73vw;
    height: calc(100vh - (34px + 33px + 55px));
}

.sidebarOpen {
    width: 58vw;
}

.header {
    height: 55px;
    width: 100%;
    background: #fafafa;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    align-items: center;
}

.messages {
    width: 100%;
    height: calc(100vh - (34px + 33px + 55px));
}

.create {
    width: 100%;
    height: auto;
    background: #fafafa;
}

/* overlay section drop zone */
.dropzone {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 0;
}
  
.box {
    width: 100%;
    height: 100%;
    min-height: 0;
}
  
.backgroundOverlay {
    z-index: 9;
    background-color: white;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
  
.contentOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.boxSidebarOpen {
    width: 58vw;
}

@media only screen and (max-width: 720px) {
    .container {
        width: 100vw;
    }
}
.AttachmentIcon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}

.AttachmentIcon svg {
  margin: 0;
  margin-right: 2px;
  width: 15px;
  height: auto;
}

.AttachmentIcon p {
  margin: 0;
  line-height: 1;
}

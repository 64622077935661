.CommentIcon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 6px;
}

.CommentIcon svg {
  margin: 0;
  margin-right: 5px;
  width: 15px;
  height: auto;
}

.CommentIcon p {
  margin: 0;
  line-height: 1;
}

.SeparatorLoginButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 4.5vh;
    margin-bottom: 5vh;
}

.SeparatorLoginButton__leftSection {
    width: 40%;
}

.SeparatorLoginButton__midSection {
    width: 20%;
    text-align: center;
}

.SeparatorLoginButton__rightSection {
    width: 40%;
}

.SeparatorLoginButton__midSection > p {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #7A7A7A;
}

@media only screen and (max-width: 720px) {
    .SeparatorLoginButton {
        margin-top: 4vh;
        margin-bottom: 4vh;
    }

    .SeparatorLoginButton__midSection > p {
        font-size: 16px;
        line-height: 23px;
    }
  }
.sectionSmallTitle {
  margin-bottom: 10px;
}

.togglePrivateCard {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.togglePrivateCard h1 {
  margin: 0;
  font-size: 1rem;
}

.container {
    width: 130px;
    height: 29px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 14px;
    cursor: pointer;
    position: relative;
    background: #FAFAFA;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
}

.container:hover {
    opacity: 0.8;
}

.icon {
    width: 24px;
    height: 24px;
    background: #FAFAFA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon > svg {
    color: #F0B418;
    width: 20px;
}
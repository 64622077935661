.overlay {
    position:absolute;
    left:0; top:0; bottom:0; right:0;
    z-index: 1;
}

.ModalLink {
    color: inherit;
    text-decoration: none;
}

.ModalLink:hover {
    color: inherit;
    text-decoration: none;
}
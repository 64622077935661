.container {
  padding: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.ManageMultipleMembersContainer__Header {
    display: flex;
    align-items: center;
  }
  
  .ManageMultipleMembersContainer__Header h1 {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  
  .ManageMultipleMembersContainer__Header svg {
    cursor: pointer;
  }
  
  .ManageMultipleMembersContainer__smallTitle {
    color: #263238;
    font-size: 0.8rem;
  }
  
  .ManageMultipleMembersContainer__body {
    margin-top: 10px;
    max-height: 35vh;
    overflow-y: auto;
  }
  
  .ManageMultipleMembersContainer__teamMember {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.4rem;
  }
  
  .ManageMultipleMembersContainer__teamMember:hover {
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  
  .ManageMultipleMembersContainer__checkIcon {
    background-color: #2196f3;
    border-radius: 30%;
    color: white;
    cursor: pointer;
  }
  

  .applySection {
    margin-top: 15px;
  }
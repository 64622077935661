
.container {
    max-height: 85.3vh;
    overflow-y: auto;
    height: 85.3vh;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
    padding-right: 0.5rem;
}

.container::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.container::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.headerSection {
    margin-bottom: 1rem;
}

.topSection {
    display: flex;
    /* align-items: center;
    justify-content: space-around; */
    width: 100%;
}

.middleSection {
    display: flex;
    margin-top: 1rem;
    width: 100%;
}

.bottomSection {
    display: flex;
    margin-top: 1rem;
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .topSection {
        /* display: block; */
    }

    .middleSection {
        display: block;
    }

    .bottomSection {
        display: block;
    }
}


@media only screen and (max-width: 600px) {
    .container {
        max-height: none;
        overflow-y: hidden;
        height: 100%;
        padding-right: 0px;
    }

    .topSection {
        display: block;
    }

    .middleSection {
        display: block;
    }

    .bottomSection {
        display: block;
    }
}
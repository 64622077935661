.container {
    position: relative;
    /* z-index: 4 !important; */
    cursor: pointer;    
}

.icon {
    position: absolute;
    bottom: -3px;
    right: -4px;
}

.iconSuper {
    position: absolute;
    bottom: 4px;
    right: 18px;
}
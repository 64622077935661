.Event {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 0;
    justify-content: space-between;
    margin-top: -8px;
  }

  .mainContent {
      display: flex;
      align-items: flex-start;
      padding-right: 5px;
  }
  
  /* .boxDate {
    min-width: 15px;
    height: 15px;
    width: 15px;
    margin-right: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
  }
  
  .boxDate svg {
    fill: #0f3057;
    width: 15px;
  } */
  
.contentSection {
    text-align: left;
}

.contentSection__date h1 {
    font-size: 0.8rem;
    margin: 0;
}

  .contentSection__header {
    display: flex;
    align-items: center;
  }
  
  .contentSection__header__title {
    margin-right: 5px;
  }
  
  .contentSection__header__title p {
      font-size: 0.7rem;
    margin: 0;
    display: inline-block;
    margin-right: 4px;
  }
  
  .contentSection__header__title > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .contentSection__header__title img {
      margin-bottom: 0;
      width: 15px;
      height: 15px;
  }
  
  .contentSection__header__title svg {
    fill: black;
    margin: 0px;
    width: 15px;
  }

  .contentSection__time p {
      font-size: 0.6rem;
  }
  
  .bodySection__counter span {
    font-size: 0.6rem;
  }

  .bodySection__counter {
    margin-right: 10px;
  }
  
.checklistItem {
    margin-bottom: 0.5rem;
}

.checklistItem:last-child {
    margin-bottom: 0px;
}

.minHeight {
    min-height: 1rem;
    width: 100%;
}
.container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.2rem;
    cursor: pointer;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.overlayBackground {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    opacity: 0.7;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
}

.overlayContent {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.action {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: flex-end;
}

.name {
    width: 80%;
}

.dragStartTrue {
    opacity: 0.5;
}

.dragStartFalse {
    opacity: 1;
}

.hoveredTrue {
    background-color: #fafafa;
    border-radius: 3px;
}

.hoveredFalse {

}

.actionButton > svg {
    width: 22px;
    color: gray;
}

.actionButton:hover {
    opacity: 0.8;
}

.actionButton {
    margin-right: 0.3rem;
}

.actionButton:last-child {
    margin-right: 0px;
}
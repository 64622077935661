.showEvents {
  display: flex;
}

.showEvents__item {
  width: 100%;
  margin-bottom: 8px;
}

.showEvents__date {
  width: 160px;
  margin-bottom: 10px;
}

.emptyMessage {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .showEvents {
    display: block;
  }
}
.container {
    box-sizing: border-box;
    /* width: 34.6vw; */
    /* width: 474px; */
    /* min-width: 450px; */
    width: 100%;
    /* max-width: 70vw; */
    height: 166px;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 0.5rem;
    position: relative;
}

.leftBox {
    background: url('../../../../assets/BillingPage/illustrasi-1.png'), #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
}

.rightBox {
    background: url('../../../../assets/BillingPage/illustrasi-2.png'), #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
}

.title {
    margin-top: 0.5rem;
}

.mainText {
    margin-top: 1rem;
}

.statusIndicator {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
}
/* 
@media only screen and (max-width: 830px) {
    .container {
        width: 520px;
    }
} */


@media only screen and (max-width: 1024px) {
    .container {
        
    }
}

@media only screen and (max-width: 720px) {
    .statusIndicator {
        right: 10px;
        top: 10px;
    }

}
.container {
    padding: 0.5rem;
}

.footer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.formText {
    margin-bottom: 0.2rem;
}
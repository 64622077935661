.ManageSubscribersContainer__Header {
  display: flex;
  justify-content: center;
  margin: 1rem 0px;
  position: relative;
}

.subscribersHeader {
  margin-bottom: 10px;
}

.doneButton {
  position: absolute;
  right: 1rem;
  top: 1px;
  cursor: pointer;
}

.doneButton:hover {
  opacity: 0.8;
}

.ManageSubscribersContainer__smallTitle {
  color: #263238;
  font-size: 0.8rem;
}

.ManageSubscribersContainer__body {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.8rem;
  min-height: 180px;
  max-height: 180px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.ManageSubscribersContainer__body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* Handle */
.ManageSubscribersContainer__body::-webkit-scrollbar-thumb {
/* border-radius: 20px;
background-color: #c4c4c4; */
}

.ManageSubscribersContainer__body::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.ManageSubscribersContainer__teamMember {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 6px;
}

.ManageSubscribersContainer__teamMember:hover {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.ManageSubscribersContainer__checkIcon {
  background-color: #2196f3;
  border-radius: 30%;
  color: white;
  cursor: pointer;
}

.subscribersItem {
  position: relative;
}

.ManageSubscribersContainer__Title {
  margin-left: 1rem;
  margin-right: 1rem;
}

.ManageSubscribersContainer__Search {
  margin-left: 1rem;
  margin-right: 1rem;
}

.checkBoxRead:hover {
  opacity: 0.8;
}
.checkBoxRead { 
  color: #708FC7;
  cursor: pointer;
}
.checkBoxUnread { 
  color: grey;
  cursor: pointer;
}

.checkBoxUnread:hover {
  opacity: 0.8;
}

.ManageSubscribersContainer__SelectAll {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
}

.member {
  display: flex;
  align-items: center;
}
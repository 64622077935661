.container {
    width: 116px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #7A7A7A;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.icon {
    margin-right: 5px;
}

.icon > svg {
    width: 18px;
    color: #7a7a7a;
}

.container:hover {
    opacity: 0.8;
}
.container {
    display: flex;
    align-items: center;
}

.box {
    height: 10px;
    width: 15px;
    background-color: turquoise;
}

.boxTitle {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.backlog {
    background-color: #7A7A7A;
}

.ongoing {
    background-color: #32B4FD;
}

.overdue {
    background-color: #FF7171;
}

.completed {
    background-color: #42E691;
}
.Message {
    display: block;
    
    margin-bottom: 5px;
  }
  .Message span,
  .Message p,
  .Message i {
    margin: 0;
    font-size: 0.6rem;
  }
  
  .mainContainer {
    display: inline-flex;
    justify-content: flex-end;
    
  }
  
  .mainContainerNotSelf {
    display: flex;
   
    
  }
  
  .photoSection {
    margin-right: 5px;
    display: inline-block;
  }
  
  .photoSection img {
    margin: 0;
    /* height: 100%; */
  }
  
  .bodySection {
    display: inline-block;
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    max-width: 500px;
  
  }
  
  @media only screen and (max-width: 600px) {
    .bodySection {
      max-width: 350px;
    }
  }
  
  .headerMessage {
    
    align-items: center;
    
  }
  
  .contentMessage {
    display: block;
    text-align: left;
  }
  
  
  
  
  .senderName {
    margin-right: 5px;
  
    text-align: left;
  }
  
  .senderName p {
    font-weight: bold;
    font-size: 0.6rem;
  }
  
  .sentDate {
    margin-top: 20px;
  
    justify-content: flex-end;
    display: flex;
    
  }
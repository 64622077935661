.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box {
    margin-right: 1rem;
    width: 75%;
}

.action {
    cursor: pointer;
}

@media only screen and (max-width: 720px) {
    .box {
        width: 65%
    }
}
.Notification {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 10px 12px 10px 18px;
  margin-bottom: 10px;
  /* border-radius: 5px; */
  /* height: 70px; */
  background-color: white;
  /* box-shadow: 0 2px 5px #ccc; */
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.Notification:hover {
  background-color: #fafafa;
  border-radius: 0px;
}

.iconSection {
  margin-right: 5px;
  position: relative;
  padding-top: 3px;
  padding-right: 5px;
}

.notifSystemIcon {
  width: 30px;
  height: 30px;
  margin-bottom: 3px;
  margin-right: 6px;
}

.bodySection {
  width: 100%;
  max-width: 85%;
}

.fullWidth {
  max-width: 100%;
}

.bodySection__text {
  /* padding-right: 5px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.bodySection__text__title {
  width: 80%;
}
/* .bodySection__text__title h1 {
  font-size: 1rem;
  margin-bottom: 0;
} */

.bodySection__text__desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2px;
}

/* .bodySection__text__desc p {
  margin: 0;
  font-size: 0.8rem;
  margin-right: 7px;
} */

.bodySection__counter {
  /* margin-right: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.badge {
  z-index: 0;
  margin-right: 15px;
}

.selected {
  background-color: #f1f1f1;
}

.selected:hover {
  background-color: #fafafa;
}

.unselectedIcon {
  color: grey;
}

.selectedIcon {
  color: #42E691;
}

.transparent {
  opacity: 0.6;
}

.noclick {
  cursor: default;
}

.fullNameAndTeamSection {
  max-width: 80%;
}

.activityContent {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
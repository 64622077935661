.titleSection__creator {
    display: flex;
    margin-top: 10px;
  }
  
.titleSection__creator__photo {
    margin-right: 7px;
    padding-top: 7px;
  }
  
.titleSection__creator__photo img {
    margin: 0;
  }

.titleAndDateSection {
    display: flex;
    align-items: center;
}

.titleSection__creator__body h1 {
    font-size: 1.1rem;
    margin: 0;
  }
  
.titleSection svg {
    cursor: pointer;
  }

.title {
    margin-right: 10px;
}
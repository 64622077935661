.container {
    /* padding-top: 0.8rem;
    padding-bottom: 0.8rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    margin-bottom: 1rem;
}

.text {
    margin-bottom: 1.3rem;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.image > img {
    width: 450px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dontShowSection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.3rem;
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .image > img {
        width: 380px;
    }
}

@media only screen and (max-width: 720px) {
    .image > img {
        width: 250px;
    }

    .text {
        max-width: 100%;
    }
}
.container {
    width: 70px;
    border-radius: 3px;
    padding: 1px;
    display: flex;
    justify-content: center;
}

.online {
    background: #42E691;
}

.idle {
    background: #FFEEC3;
}

.offline {
    background: #B5B5B5;
}
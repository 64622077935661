.BoardPage__header {
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 7vh;
  z-index: 3;
}

/* .BoardPage__relative {
  position: relative;
} */

.BoardPage__title {
  margin-top: 10px;
}

.BoardPage__title > h1 {
  font-size: 1rem;
}

/* h1 {
  font-size: 1.2rem;
} */

.BoardPage__flex {
  display: flex;
}

.BoardPage__outerList {
  min-width: 100%;
  white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    position: relative;
}

.buttonIcon {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.buttonIcon p {
  margin: 0;
  margin-left: 5px;
}

.container {
  min-width: 100vw;
  width: max-content;
}

.mainSectionMarginRight {
  margin-right: 260px;
  transition: margin-right .1s;
}

.mainSection {
  width: max-content;
}

.sidebarOpen {
  width: max-content;
    margin-left: 240px;
    position: absolute;
    top: calc(34px + 34px + 40px);
    transition: margin-left 0.3s ease;
}

.menuSection {
  transition: 2s;
}

.filterSection {
  display: flex;
  align-items: flex-start;
  position: fixed;
  right: 7px;
}

.filterSection__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3e3e3;
  margin-right: 10px;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.filterSection__button > p {
  margin-bottom: 0;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 0.8rem;
}

.filterSection__button:hover {
  background-color: #212529;
  color: white;
}

.filterSection__button > svg {
  width: 20px;
}

.activeFilterIcon svg {
  margin-left: 4px;
  color: #28a745;
  width: 20px;
}

.forLoading {
  position: fixed;
  top: 106px;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.forLoading__box {
  background-color: white;
  opacity: 0.8;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 106px;
  left: 0;
  z-index: 3;
}

.forLoading__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 4;
  margin-bottom: 20vh;
}

@media only screen and (max-width: 720px) {
  .activeFilterIcon svg {
    margin-left: 0px;
    width: 16px;
  }
}
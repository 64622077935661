.content {
    height: 54vh;
    max-height: 54vh;
    overflow-y: auto;
}

.content::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.content::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.content::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}
.container {
  padding: 2rem 2.5rem;
}

.questionsSection {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 25px;
}

.questionsSection__title {
  margin-bottom: 20px;
}

.questionsSection__schedule {
  margin-bottom: 20px;
}


.questionsSection__time {
  margin-bottom: 15px;
}

.Subscribers {
  margin-bottom: 20px;
  position: relative;
}

.subscribersHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}

.subscribersHeader h1 {
  margin: 0;
  margin-right: 10px;
}

.subscribersItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subscribersItem span {
  margin-right: 5px;
  font-size: 0.8rem;
}

.plusButton {
  background-color: lightgrey;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plusButton:hover {
  opacity: 0.8;
}

.actionSection > button {
  margin-right: 5px;
}

.manageSubscribers {
  margin-top: 5px;
  /* margin-bottom: 20px; */
}
.container {
    padding: 2rem 2.5rem;
}

.headerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
.eventsSection__setPublic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.eventsSection__setPublic > a {
    text-decoration: underline;
}

.eventsSection__setPublic > p {
    font-weight: bold;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 10px;
    position: relative;
    flex-wrap: wrap;
}

.logo a {
      color: #007bff;
}

.logo a:hover {
    text-decoration: underline;
    color: #007bff;
}

.logo > p {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 0.9rem;
}

.logo__gCal {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;
}

.logo__gCal > img {
    width: 25px;
    margin-right: 5px;
}

.logo__iCal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}

.logo__iCal > img {
  width: 25px;
  margin-right: 5px;
}

.logo__outlook {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.logo__outlook > img {
  width: 25px;
  margin-right: 5px;
}

.formSection {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 35vw;
}

.unsetSection {
    margin-top: 20px;
    position: relative;
}

.unsetSection > p {
    font-size: 0.7rem;
    cursor: pointer;
    color: #ff4646;
}

.unsetSection > p:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    /* targetting emoji pop up  */
    .eventsSection__setPublic {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .logo {
        justify-content: flex-start;
    }

    .formSection {
        width: 100%;
    }
  }
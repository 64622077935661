.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexStart {
    justify-content: flex-start;
}

.spinner {
    margin-left: 10px;
}
.container {
    display: flex;
    align-items: center;
    /* width: 279px; */
    height: 32px;
    /* white */
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    padding-left: 1.2rem;
    padding-right: 0.5rem;
    cursor: pointer;
}

.container:hover {
    background-color: #f7f7f7;
}

.icon {
    margin-right: 0.2rem;
}

.icon > svg {
    width: 22px;
    color: #B5B5B5;
}

.selected {
    background: #FFEEC3;
}

.selected:hover {
    background: #FFEEC3;
}
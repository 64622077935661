.container {
  padding: 2rem 2.5rem;
}

.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputTitleSection {
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.inputTitleSection textarea {
  width: 100%;
  margin-right: 5px;
  position: relative;
  outline: 0;
  border: 0;
  width: 100%;
  resize: none;
  font-size: 2rem;
}

.inputTitleSection svg {
  cursor: pointer;
}

.emojiPicker {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.docsSection {
  min-height: 24vh;
  display: flex;
  position: relative;
  flex-direction: column;
}


.Subscribers {
  margin-bottom: 20px;
  position: relative;
}

.subscribersHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}

.subscribersHeader h1 {
  margin: 0;
  margin-right: 10px;
  font-size: 1rem;
}

.subscribersItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subscribersItem span {
  margin-right: 5px;
  font-size: 0.8rem;
}

.plusButton {
  background-color: lightgrey;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plusButton:hover {
  opacity: 0.8;
}


.actionSection {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.actionSection svg {
  margin-left: 7px;
  cursor: pointer;
  width: 20px;
}

.actionSection > div > button {
  margin-right: 5px;
}

.emojiButton {
  display: flex;
  justify-content: flex-end;
  position: relative;
}


/* targetting emoji pop up  */
.emojiButton > div > div {
  top: 0;
  right: 2vw;
}

@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .emojiButton > div > div {
    top: 0;
    left: 0;
    right: 0;
  }

  .inputTitleSection textarea {
    font-size: 1.5rem;
  }
}

.manageSubscribers {
  /* margin-top: 5px; */
  margin-bottom: 20px;
}
.list {
    min-height: 55vh;
    /* background-color: beige; */
    max-height: 55vh;
    overflow-y: auto;
}

.listItems {
    display: flex;
    flex-wrap: wrap;
}

.list::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.list::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.filter {
    position: absolute;
    max-width: 760px;
    top: 1.5rem;
    right: 1.5rem;
}

.item {
    margin-bottom: 1rem;
    margin-right: 1rem;
}
.container {
  z-index: 1210;
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  max-width: 100vw;
  padding: 0.5rem 1rem 0.5rem 1rem;
  height: 33px;
  background-color: #ffffff;
  align-items: center;
  border-bottom: 1px solid #d6d6d6;
  top: 0;
  left: 0;
  /* box-shadow: 0 1px 4px #ccc; */
}

.sidebarOpenKanban {
  position: fixed;
  width: 100vw;
}

.sidebarOpenNotKanban {
  
}

.Navbar__brand {
  cursor: pointer;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 203px;
}

.drawerIcon {
  margin-right: 10px;
  cursor: pointer;
}

.drawerIcon:hover {
  opacity: 0.8;
}

.logoIcon > img {
  height: 20px;
}

.NavHeader__image {
  height: 30px;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;

}

.popUpSidebarSection {
  width: 0px;
  position: absolute;
  top: 3px;
  left: 240px;
}

.searchSection {
  width: 40vw;
  position: absolute;
  top: 3px;
  left: 29vw;
}

.rightSection {
  display: flex;
  align-items: center;
}

.navIconSection {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 14px;
}

.navIconSectionLast {
  margin-right: 11px;
}

.navMemberSection {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.breadCrumb ol li {
  color: white;
}

.breadCrumb svg {
  fill: black;
}

.breadcrumbItem {
  background-color: white;
  padding: 4px;
  border-radius: 3px;
}

.breadcrumbItem span {
  color: black;
}

.breadcrumbItem li {
  color: white;
}

.MuiBreadcrumbs-separator {
  color: white;
}

.companyNameSection {
  margin-right: 16px;
}

.rightColumn {
  display: flex;
  align-items: center;
}

.drawerAndLogo {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .searchSection {
    width: 30vw;
  }
}

@media only screen and (max-width: 720px) {
  .Navbar {
    padding: 0.5rem 0.9rem 0.5rem 0.7rem;
  }

  .searchSection {
    left: 14vw;
    width: 24vw;
  }

  .companyNameSection {
    margin-right: 4px;
  }

  .navIconSection {
    margin-right: 8px;
  }
}
.title {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.description {
    margin-bottom: 2rem;
}

.permissionInfo {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.explanation {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.action {
    display: flex;
    justify-content: flex-end;
}

.listRoleIconAction {
    margin-left: 0.6rem;
    cursor: pointer;
}

.listRoleIconAction > svg {
    color: grey;
    /* width: 32px; */
}
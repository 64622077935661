.bodyInfinityScroll {
  display: block;
  position: relative;
  scroll-margin: 3rem;
}

.infiniteScroll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 2rem;
}
.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eventsSection {
  padding: 2rem 2.5rem;
}

.postsSection {
  min-height: 69vh;
  max-height: 69vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
}

.eventsSection__calendar {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.eventsSection__items {
  padding-left: 15px;
}

.eventsSection__setPublic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventsSection__setPublic > a > p {
  text-decoration: underline;
  font-size: 0.8rem;
  text-align: center;
}

.showEvents {
  display: flex;
}

.showEvents__item {
  width: 100%;
}

.showEvents__date {
  width: 160px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .showEvents {
    display: block;
  }
}
.container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    margin-bottom: 1rem;
}

.text {
    margin-bottom: 3rem;
}

.formSection {
    width: 60%;
}

.form {
    height: 170px;
    background: #FFE49B;
    border: 1px solid #FDC532;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
    padding: 1rem;
}

.form__input {
    margin-top: 10px;
    margin-bottom: 10px;
}

.button {
    display: flex;
    justify-content: end;
}

@media only screen and (max-width: 1024px) {
    .formSection {
      width: 60%;
    }

    .text {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    .title {
        padding-left: 10rem;
        padding-right: 10rem;
    }
  }
  
  @media only screen and (max-width: 720px) {
    .container {
        background-size: cover;
    }

    .formSection {
      width: 70%;
    }

    .text {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .title {
        padding-left: 5rem;
        padding-right: 5rem;
    }
  }
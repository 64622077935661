.container {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #D6D6D6;
    padding: 1rem;
    width: 100%;
}

.actionButton {
    display: flex;
    align-items: center;
}

.actionInput {
    margin-right: 1.1rem;
    width: 100%;
}


@media only screen and (max-width: 1024px) {
   
}
  
@media only screen and (max-width: 720px) {
    .container {
        display: block;
        width: auto;
        height: 110px;
    }

    .actionButton {
        margin-top: 0.5rem;
    }
}
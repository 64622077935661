.container {
  display: flex;
  max-width: 80%;
  margin-bottom: 20px;
}

.balloon {
  display: flex;
}

.content {
  position: relative;
  max-width: 40vw;
}

.vectorBalloon {
  background: #ffffff;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  width: 24px;
  height: 16px;
  margin-right: -1px;
  z-index: 2;
}

.vectorBalloonSelf {
  background: #FFEEC3;
  clip-path: polygon(0 0, 100% 0, 0% 100%);
  width: 24px;
  height: 16px;
  margin-left: -1px;
  z-index: 2;
}

.balloonMessage {
  background: #FFFFFF;
  border-radius: 0px 10px 10px 10px;
  min-height: 60px;
  padding: 10px 1rem 30px 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 95%;
  position: relative;
  min-width: 180px;
}

.name {
  margin-left: 18px;
}

.timeStamp {
  position: absolute;
  bottom: 5px;
  right: 3px;
}

.seen {
  position: absolute;
  bottom: 3px;
  left: 7px;
}

.sentIcon {
  position: absolute;
  bottom: 5px;
  right: -1.3rem;
}

.photo {
  margin-right: 5px;
}

.photo > div {
  margin: 0 !important;
}

.photo > div > img {
  width: 38px !important; 
  height: 38px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.name > h1 {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}

.attachment__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.attachment__title {
  text-align: center;
}

.attachment__title > p {
  margin-bottom: 10px !important;
}

.balloonMessage:hover > .iconOption {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 8px;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background-color: #fafafa;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.iconOption { 
  display: none;
}

.iconOptionDeleted { 
  display: none;
}

.iconOption > svg {
  width: 24px;
}

.playIcon > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50px;
  left: 130px;
  color: white;
}
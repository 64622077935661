.main__icon {
  position: relative;
}

.main__icon img {
  max-width: 140px;
  max-height: 80px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .main__icon img {
    max-width: 80px;
    max-height: 50px;
  } 
}

.playIcon > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 25px;
  left: 50px;
  color: white;
}
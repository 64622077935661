.container {
    padding: 0.8rem;
    min-height: 100px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.user {
    display: flex;
    align-items: center;
}

.photo {
    margin-right: 0.2rem;
}

.title {
    margin-top: -0.1rem;
}

.bubble {
    /* padding-right: 1.7rem; */
}

.status__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.status__item__box__date > div {
    margin-right: 0px;
}

.status {
    margin-bottom: 0.2rem;
}

.bio {
    margin-top: 1.3rem;
}
.container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .box {
    width: 100px;
    height: 50px;
    background-color: beige;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

.content {
    /* width: 120px; */
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.visitIllustration > img {
    width: 92px;
}
.container {
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  display: inline-flex;
  width: 70px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
}

.color {
  border: 0;
  background-color: green;
  color: white;
}

.container {
    position: relative;
    width: 100%;
}

.backButton {
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
}

.backButton:hover {
    opacity: 0.8;
}

.bodySection {
    display: flex;
    margin-top: 2rem;
    width: 100%;
}

.subTitle {
    margin-bottom: 5px;
}

.billingInfo {
    margin-top: 1.5rem;
}

.addOnSection {
    margin-top: 1.5rem;
}

.notes {
    margin-top: 1.5rem;
}

.orderAndNotes {
    width: 60%;
    margin-top: 10px;
    margin-right: 1rem;
}

.orderAmountAndButton {
    width : 40%;
    background-color: #f7f7f7;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    padding: 10px;
    height: 100%;
}

.item {
    display: flex;
    align-items: center;
    /* border-top: 1px solid #e7e7e7; */
    border-bottom: 1px solid #e7e7e7;
    height: 50px;
}

.columnTitle {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 0.5rem;
}

.columnTitle__plan {
    width: 80px;
    margin-right: 4px;
}

.columnTitle__desc {
    width: 250px;
    margin-right: 4px;
}

.descText {
    margin-bottom: 0.3rem;
}

.columnTitle__qty {
    width: 50px;
    margin-right: 4px;
}

.columnTitle__price {
    width: 100px;
    margin-right: 4px;
}

.columnTitle__total {
    width: 100px;
    margin-right: 4px;
}

.columnTitle__subscriptionDate {
    width: 160px;
    margin-right: 4px;
}

.amountText {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.amountText__title {
    width: 120px;
    margin-right: 5px;
}

.amountText__body {
    min-width: 100px;
}

.amountText__total {
    margin-top: 1rem;
}

.notesChoosePaymentMethod {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.button {
    margin-top: 1rem;
}

.selectCoupon {
    margin-top: 0.5rem;
}


@media only screen and (max-width: 1024px) {
    
}

@media only screen and (max-width: 720px) {
    .bodySection {
        display: block;
        width: auto;
    }

    .orderAndNotes {
        width: 100%;
        margin-bottom: 1.5rem;
        margin-right: 0px;
    }
    
    .orderAmountAndButton {
        width : 100%;
    }

    .backButton {
        top: -35px;
    }

    .items {
        width: 105vw;
    }
    .columnTitle {
        width: 105vw;
    }

    .scrollListItems {
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
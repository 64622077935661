.container {
    display: flex;
    height: 40px;
    background-color: #ffffff;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4px;
    padding-bottom: 5px;
    z-index: 3;
    /* box-shadow: 0 1px 4px #d6d6d6; */
    border-bottom: 1px solid #d6d6d6;
    position: relative;
    transition: margin-left 0.3s ease;
}

.sidebarOpen {
    /* margin-left: 240px; */
    /* width: calc(100% - 240px); */
}

.bottomBar__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

.bottomBar__leftSection {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 10px;
    position: relative;
}

.bottomBar__globalActionSection {
    max-width: 120%;
    max-width: 120%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: flex;
    align-items: center;
}

.bottomBar__globalActionSection::-webkit-scrollbar {
    display: none;
}

.bottomBar__rightSection {
    display: flex;
    align-items: center;
    width: 0%;
    justify-content: flex-end;
    margin-left: 10px;
    position: relative;
}

@media only screen and (max-width: 720px) {
    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    /* .bottomBar__leftSection {
        width: 100%;
    }
     */
}
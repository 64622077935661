.container {
    width: 338px;
    height: 356px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 3rem 15px 1rem 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.imageSection {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    width: 250px;
}

@media only screen and (max-width: 1024px) {
    .container {
        width: 258px;
        height: 306px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image {
        width: 210px;
    }
  }
  
  @media only screen and (max-width: 720px) {
    .container {
        width: 238px;
        height: 256px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image {
        width: 180px;
    }

  }
.SwitchComponent {
  /* margin-bottom: 20px; */
  position: relative;
}

.SwitchComponentHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}

.SwitchComponentTitle {
  margin: 0;
  font-size: 1rem;
}

.SwitchComponentTitleBold {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.SwitchComponentContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.SwitchComponentContentLabel {
  font-size: 0.8rem;
}
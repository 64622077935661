.ErrorPage__container {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sidebarOpen {
  width: calc(100% - 240px);
  margin-left: 240px;
}

.ErrorPage__container button {
    margin-top: 10px;
}

.ErrorPage__main {
  width: 50vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.ErrorPage__main__content {
  margin-left: 80px;
  width: 30vw;
  height: auto;
  text-align: center;
}

.ErrorPage__main__content__text {
  margin-bottom: 40px;
}

.ErrorPage__main__content__text > h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  color: #0C2044;
}

.ErrorPage__main__content__text > p {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #7A7A7A;
}

.ErrorPage__main__content__button {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ErrorPage__main__content__button > div {
  margin-bottom: 15px;
}

@media only screen and (max-width: 720px) {
  .ErrorPage__main {
    width: 100vw;
  }
  
  .ErrorPage__main__content {
    width: 100vw;
    margin-left: 0px;
  }
}
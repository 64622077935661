.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.imageSection > img {
    width: 145px;
    margin-bottom: 43px;
}

.textSection {
    text-align: center;
    width: 50vw;
}

.headline {
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 1024px) {
    .imageSection > img {
        width: 100px;
        margin-bottom: 35px;
    }

    .textSection {
        width: 60vw;
    }
}

@media only screen and (max-width: 720px) {
    .imageSection > img {
        width: 80px;
        margin-bottom: 28px;
    }
    .textSection {
        width: 70vw;
    }
    .headline {
        margin-bottom: 1rem;
    }
}
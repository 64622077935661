.container {
  padding: 1rem 20vw 0px 20vw;
}

.sidebarOpen {
  margin-left: 240px;
  padding-right: 10vw;
  padding-left: 10vw;
}

.headerSection > img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bodySection {
  min-height: 55vh;
  display: block;
  position: relative;
  background-color: white;
  padding-left: 2rem;
  padding-right: 2rem;
}

.mainContainer {
  
  /* max-height: 69vh;
  overflow-y: auto;
  overflow-x: hidden; */
  display: flex;
  position: relative;
  flex-direction: column;
}

.markReadSection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.markReadSection > p {
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 0.8rem;
  color: grey;
  cursor: pointer;
}

.newSection {
  margin-bottom: 5px;
}

.newSection__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newSection__header p {
  cursor: pointer;
  text-decoration: underline;
  margin-right: 10px;
}

.newSection__header p:hover {
  opacity: 0.8;
}

.emptyNotif {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 30px;
}

.emptyNotif img {
  width: 100px;
  margin-bottom: 10px;
}

.emptyNotif h1 {
  margin: 0;
}

.infiniteScroll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}


.checkBoxRead { 
  color: #28a745;
  cursor: pointer;
  /* margin-right: 10px; */
}

.checkBoxRead:hover {
  opacity: 0.8;
}

.checkBoxUnread { 
  color: grey;
  cursor: pointer;
  /* margin-right: 10px; */
}

.checkBoxUnread:hover {
  opacity: 0.8;
}

.selectAllSection {
  /* margin-top: 15px;
  margin-bottom: 5px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  cursor: pointer;
}

.selectAllSection > p {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-right: 5px;
  color: grey;
  text-decoration: none;
}

.selectAllSection__uncheckIcon {
  cursor: pointer;
}


@media only screen and (max-width: 768px) {
  .container {
    padding: 0px;
  }

  .headerSection > img {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}
.container {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    /* margin-left: 10px; */
    margin-top: 5px;
}

.container > p {
    font-size: 0.8rem;
    font-weight: bold;
}
.container > svg {
    width: 16px;
}

.sending > svg {
    color: #B5B5B5;
}

.readPart > svg {
    color: #B5B5B5;
}

.readAll > svg {
    color: #FFD974;
}
.ListContainer__outerList {
  /* Add below so this div always fit child elements width */
  width: max-content;
  min-height: 67vh;
  max-height: 67vh;
  display: flex;
  /* white-space: nowrap;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; */
  align-items: flex-start;
  position: relative;
  padding-top: 10px;
  z-index: 1;
  padding-right: 10px;
  padding-left: 10px;
}

.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerSection__title {
  padding-right: 8px;
  display: flex;
  width: 90%;
  cursor: pointer;
  align-items: center;
}

.headerSection__title__completeIcon__container {
  position: relative;
}

.headerSection__title__completeIcon {
  margin-right: 5px;
}

.headerSection__title__completeIcon > svg {
  width: 15px !important;
  color: #28a745;
}

.headerSection__title__completeIcon:hover {
  opacity: 0.8;
}

.headerSection__title__blockedIcon > svg {
  color: red;
}

.headerSection__more {
  position: relative;
  width: 10%;
}

.headerSection__title h1 {
  width: 100%;
  margin: 0;
}

.ListContainer__noMargin {
  margin-left: 0;
  margin-right: 0;
}

.ListContainer__noPadding {
  padding-left: 0;
  padding-right: 0;
}

.ListContainer__buttonBottom {
  border-radius: 3px;
  padding: 4px 2px 4px 2px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ListContainer__buttonBottom:hover {
  background-color: #d8d8d8;
}

.ListContainer__buttonBottom svg {
  margin-right: 3px;
  width: 20px;
}

.ListContainer__buttonBottom a {
  font-size: 0.8rem;
}

/* with overflow vertical bug no nested scrolling supported */
/* .ListContainer__minHeight {
  min-height: 20vh;
} */


/* with overflow vertical bug no nested scrolling supported */
.ListContainer__minHeight {
  min-height: 10vh;
  max-height: 68vh;
}

/* with overflow vertical bug no nested scrolling supported */
/* .ListContainer__listCardsSection {
  margin-right: 10px;
  margin-bottom: 30px;
} */

/* with overflow vertical bug no nested scrolling supported */
.ListContainer__listCardsSection {
  overflow-y: auto;
  max-height: 68vh;
  margin-right: 10px;
  position: relative;
}

.createListSection {
  position: relative;
  margin-top: 10px;
  flex: 0 0 15rem;
  margin-right: 10px;
  margin-left: 10px;
  z-index: 1;
  width: 200px;
}

.listContainer {
  flex: 0 0 17em;
}

.headerSection__title__text {
  margin-left: 2px;
}

.headerSection__title__text h1 {
  font-weight: bold !important;
  font-size: 0.8rem !important;
}

.loaderInfinityScroll {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
.container {
    padding: 10px 2.3rem 10px 2.3rem;
    flex-direction: column-reverse;
    position: relative;
    display: flex;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}
  
.container::-webkit-scrollbar {
    width: 7px;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.container::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.infiniteScroll {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 10px; */
    margin-top: 10px;
    margin-bottom: 20px;
}
.container {
    padding: 2rem;
    width: 100%;
    background-image: url(../../../assets/WelcomePage/company-illustration.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    display: flex;
}

.logoutIcon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
}
.container {
    width: 100%;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    justify-content: space-between;
}

.member {
    display: flex;
}

.removeButton {
    margin-left: auto;
    position: relative;
}

.member__status {
    margin-left: 10px;
}

.memberAndRole {
    width: 300px;
    display: flex;
    align-items: center;
}

.nameAndBadge {
    display: flex;
    align-items: center;
}

.badge {
    margin-left: 0.3rem;
}

@media only screen and (max-width: 1024px) {
    /* .container {
        
    } */
}

@media only screen and (max-width: 720px) {
    /* .container {
        display: block;
        width: auto;
        height: 120px;
        padding-top: 1rem;
    }

    
    .removeButton {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.7rem;
    }

    .selectRoleButton {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.7rem;
    } */
}
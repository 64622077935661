.ImageHeader {
    margin-left: auto;
}

.titleSection {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
    padding-left: 20%;
    padding-right: 20%;
}

.formSection {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20%;
    padding-right: 20%;
}

.continueSection {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20%;
    padding-right: 20%;
}
  
.form {
    margin-bottom: 15px;
}

.formText {
    margin-bottom: 10px;
}

.action {
    margin-top: 1rem;
}

.alertEmptySection {
    margin-top: 1rem;
}

.forgotPasswordSection {
    display: flex;
    justify-content: flex-end;
}

.loginNowSection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.loginNowSection__text {
    margin-right: 5px;
}

.alertClickResetPasswordSection {
    margin-top: 1rem;
}
.container {
    height: 30px;
    width: 200px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    padding-right: 0.6rem;
}

.arrow {
    margin-left: auto;
}

.arrow > svg {
    color: #B5B5B5;
    width: 22px;
    margin-right: 5px;
}

.different {
    border: 1px solid #708FC7; 
}

.differentIcon > svg {
    color: #FDC532;
}

.text {
    min-width: 110px;
    overflow: hidden;
}

.text > p {
    overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Set the maximum number of lines to display */
  -webkit-box-orient: vertical;
}

.buttonWidthSm {
    width: 150px;
}

.buttonWidthLg {
    width: 250px;
}

.textWidthSm {
    min-width: 76px;
}


@media only screen and (max-width: 1024px) {
    .container {
        width: 190px;
    }

    .buttonWidthSm {
        width: 140px;
    }
    
    .buttonWidthLg {
        width: 240px;
    }

    .textWidthSm {
        min-width: 60px;
    }
}

@media only screen and (max-width: 720px) {
    .container {
        width: 34px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
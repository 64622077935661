.hidden {
    display: none;
  }
  
  .SmallCard {
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 3px 3px #ccc;
    padding: 3px 5px 3px 5px;
    width: 100%;
    max-width: 240px;
    margin: 0 0 2px 0;
    text-align: left;
    position: relative;
  
  }
  
  .SmallCard h1 {
    font-size: 0.7rem;
    margin-bottom: 4px;
  }
  .SmallCard p {
    font-size: 0.5rem !important;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .SmallCard svg {
      width: 15px !important;
  }
  
  .SmallCard button {
    position: relative;
    float: right;
    margin-right: 10px;
    margin-top: 50px;
  }
  
  .SmallCard:hover {
    cursor: pointer;
  }
  
  .labelsSection {
    display: flex;
    flex-wrap: wrap;
  }

  .labelsSection > div {
      padding-top: 0;
      padding-bottom: 1px;
      min-height: 0;
  }

  .labelsSection > div > p {
      font-size: 0.4rem;
  }

  .imageListSection {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
  }
  
  .imageMemberSection {
    display: flex;
    flex-wrap: wrap;
  }
  .imageMemberSection img {
      margin-top: 0;
    margin-bottom: 0;
    width: 15px;
    height: 15px;
  }
  
  .othersPropertiesSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .othersPropertiesSection > div > svg {
      width: 12px;
  }

  .othersPropertiesSection > div {
      padding-top: 0;
      padding-bottom: 0;
  }

  .listTitle h1 {
      text-decoration: underline;
  }
  
.container {
    display: flex;
    height: 34px;
    background-color: #ffffff;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4px;
    padding-bottom: 5px;
    z-index: 3;
    /* box-shadow: 0 1px 4px #d6d6d6; */
    border-bottom: 1px solid #d6d6d6;
    position: relative;
    transition: margin-left 0.3s ease;
}

.sidebarOpen {
    /* margin-left: 240px; */
    /* width: calc(100% - 240px); */
}

@media only screen and (max-width: 720px) {
    .container {
        padding-left: 1rem;
    }
  }
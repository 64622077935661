.container {
    border: 1px solid #D6D6D6;
    height: 50px;
}

.container:hover {
    background-color: #FFEEC3;
    border: 1px solid #B5B5B5;
}

.noLeftBorder {
    border-left: 0px;
}

.noRightBorder {
    border-right: 0px;
}

.noLeftRightBorder {
    border-left: 0px;
    border-right: 0px;
}

.noTopLeftBorder {
    border-top: 0px;
    border-left: 0px;
}

.noTopRightBorder {
    border-top: 0px;
    border-right: 0px;
}

.noTopLeftRightBorder {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
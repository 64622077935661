.container {
    width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red;
    padding: 2px;
    border-radius: 3px;
    padding-right: 6px;
    padding-left: 6px;
    cursor: default;
}

.true {
    background-color: #0C2044;
    border-color: black;
}

.false {
    background-color: #32B4FD;
    border-color: #32B4FD;
}
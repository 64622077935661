.container {
    background-color: #f7f7f7;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 140px; */
    min-height: 110px;
}
.container {
    height: 40px;
    width: 2px;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
}

.box {
    width: 30px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftBox {
    left: 0;
    top: 0;
}

.rightBox {
    right: 0px;
    top: 0;
}

.leftBoxAll {
    left: -10px;
    top: -5px;
    height: 60px;
}

.rightBoxAll {
    right: -10px;
    top: -5px;
    height: 60px;
}

.box > svg {
    width: 20px;
    color: #7a7a7a;
}

.rightGradient {
    background-image: linear-gradient(to right, rgba(221, 221, 221 ,0.1), rgba(221, 221, 221, 0.8));
}

.leftGradient {
    background-image: linear-gradient(to left, rgba(221, 221, 221 ,0.1), rgba(221, 221, 221, 0.8));
}
.tab {
    width: 99px;
    height: 31px;
    border: 2px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    user-select: none;
    display: flex;
    padding: 2px;
}

.selected {
    background: #7A7A7A;
    border: 1px solid #7A7A7A;
}

.unselected {
    background: #FFFFFF;
}

.firstItem {
    margin-left: 8px;
}

.container {
    height: 55px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 0.5rem;
    min-height: 100%;
}

.card {
    /* margin-right: 0.5rem; */
    margin-bottom: 0.7rem;
    width: 300px;
}
.container {
    width: 100%;
    /* height: 65px; */
    background: #ffffff;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 5px;
    /* cursor: pointer; */
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
    /* display: flex;
    align-items: center; */
    margin-bottom: 10px;
    /* height: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container:hover {
    /* background: #FAFAFA; */
}

.header {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.user {
    display: flex;
    align-items: center;
}

.photo {
    margin-right: 0.2rem;
}

.title {
    /* margin-top: -0.1rem; */
}

.bubble {
    /* padding-right: 1.7rem; */
}

.status__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.status__item__box__date > div {
    margin-right: 0px;
}

.status__item__box {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
}

.status {
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}

.bio {
    /* margin-top: 0.5rem; */
}
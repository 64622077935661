.container {
  z-index: 10;
  position: relative;
  background-color: #e3e3e3;
  padding-top: 10px;
  padding-right: 20px;
  /* margin-right: -10px; */
  margin-top: -87px;
}
.formSection {
  margin-left: 10px;
  margin-right: -10px;
  margin-bottom: 8px;
}

.actionSection button {
  margin-right: 5px;
}

.togglePrivateCard {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.togglePrivateCard h1 {
  margin: 0
}

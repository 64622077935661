.box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 33px - 33px - 41px - 34px);
}

.container {
    width: 70vw;
    height: 70vh;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    padding: 3rem;
}

.sidebarOpen {
    margin-left: 240px;
}

.leftSection {
    width: 50%;
}

.rightSection {
    width: 50%;
    padding-left: 3rem;
}

.header {
    margin-bottom: 20px;
}

.header__title {
    margin-bottom: 7px;
}

.infoSection {
    margin-bottom: 20px;
}

.infoSection__title {
    margin-bottom: 12px;
}

.infoSection__item {
    padding: 10px 5px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
}

.infoSection__item__desc {
    padding: 10px 5px 3rem 5px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
}

.buttonSection {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}


@media only screen and (max-width: 1024px) {
   
  }
  
  @media only screen and (max-width: 720px) {
    .container {
        display: block;
        width: auto;
        height: auto;
    }
    
    .leftSection {
        width: 100%;
        margin-bottom: 1.2rem;
    }
    
    .rightSection {
        width: 100%;
        padding-left: 0px;
    }
  }

.container {
    width: 240px;
    height: 100vh;
    background-color: white;
}

.mainSection {
    padding-bottom: 0.5rem;
    padding-top: 0.3rem;
    background-color: #fafafa;
}

.listSection {
    max-height: 77vh;
    overflow-y: auto;
    height: 77vh;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
}

.listSection::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.listSection::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}

.container {
    position: relative;
    /* height: 100%; */
    /* width: 100%; */
  }
.box {
width: 100%;
height: 100%;
}

.backgroundOverlay {
z-index: 9;
background-color: white;
opacity: 0.7;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
border-radius: 3px;
}

.contentOverlay {
display: flex;
align-items: center;
justify-content: center;
z-index: 10;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
}
.container {
    height: 56.2vh;
}

.chart {
    margin-bottom: 1rem;
}

.item {
    /* width: 100%; */
    display: flex;
    justify-content: flex-start;
    height: 100%;
}

.itemBody {
    width: 49%;
}


@media only screen and (max-width: 1024px) {
    .container {
        max-height: 77.3vh;
        overflow-y: auto;
        height: 77.3vh;
        /* padding: 0px 0px 12px 0px; */
        overflow-x: hidden;
        padding-right: 0.5rem;
    }
    
    .container::-webkit-scrollbar {
        width: 6px;
    }
    
    /* Handle */
    .container::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: #c4c4c4;
    }
      
    .container::-webkit-scrollbar-thumb:hover {
        background: #d1d1d1;
    }

    .item {
        /* width: 100%; */
        display: block;
    }
    
    .itemBody {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {

    .container {
        max-height: none;
        overflow-y: hidden;
        height: 100%;
        padding-right: 0px;
        margin-bottom: 1rem;
    }

    .item {
        /* width: 100%; */
        display: block;
    }
    
    .itemBody {
        width: 100%;
    }
}
.container {
  display: block;
  /* align-items: flex-start; */
  margin-bottom: 8px;
  padding: 15px 15px 15px 15px;
  min-height: 100px;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.photoSection {
  margin-right: 5px;
}

.photoSection img {
  margin: 0;
}

.contentSection {
  width: 100%;
}

.contentSection__header__title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.contentSection__header__title__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-left: 8px;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 2px 4px;
}

.boxTransparentIconHeader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fafafa;
  border-radius: 5px;
  z-index: 0;
}

.contentSection__header__title__icon__subaction {
  margin-right: 12px;
  z-index: 1;
}

.contentSection__header__title__icon__subactionLast {
  z-index: 1;
}

.contentSection__header__date {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.completePost {
  margin-left: 5px;
}

.creator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 45px;
}

.contentSection__content {
  margin-top: 14px;
}

.photoAndUser {
  display: flex;
}

.photo {
  margin-right: 5px;
}
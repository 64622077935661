.container {
    margin-bottom: 0.2rem;
}
.contentSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .closeAndFilterCount {
    display: flex;
    align-items: center;
  }

.closeIcon {
    margin-left: 10px;
    cursor: pointer;
}

.closeIcon:hover {
    opacity: 0.8;
}

/* .filterCount {
    cursor: pointer;
}

.filterCount:hover {
    opacity: 0.8;
} */

.reset {
    cursor: pointer;
    margin-left: 10px;
}

.reset:hover {
    opacity: 0.8;
}

.applySection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.1rem;
    margin-right: 2.1rem;
  }

  .activeFilterIcon > svg {
    margin-left: 4px;
    color: #42E691;
    width: 18px;
  }

  @media only screen and (max-width: 720px) {
    .activeFilterIcon > svg {
        margin-right: 2px;
      }
  }
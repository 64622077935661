.container {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 27rem;
  padding-right: 27rem;
}

.sidebarOpen {
  margin-left: 240px;
  padding-left: 20rem;
  padding-right: 20rem;
}

.titleSectionEdit {
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.photoSection {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 100%;
}

.bioSection {
  width: 100%;
}

.photoSection > img {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 100%;
}

.profileText {
  margin-bottom: 0.5rem;
}

.descText {
  margin-top: 2rem;
}

.action {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.sensitiveInfoSection {
  margin-top: 1.5rem;
  width: 100%;
}


@media only screen and (max-width: 1024px) {
  .container {
    padding-left: 12rem;
    padding-right: 12rem;
  }
}

@media only screen and (max-width: 720px) {
 .container {
  padding-left: 1rem;
    padding-right: 1rem;
 }
}
.actionSection {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  
  .actionSection svg {
    margin-left: 7px;
    cursor: pointer;
    width: 20px;
  }
  
  /* targetting emoji pop up  */
  .emojiButton > div > div {
    top: 0;
    right: 2vw;
  }
  
  @media only screen and (max-width: 600px) {
    /* targetting emoji pop up  */
    .emojiButton > div > div {
      top: 0;
      left: 0;
      right: 0;
    }
  }

.container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.detailInfo {
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.container {
    padding: 3rem 15rem 3rem 15rem;
}

.sidebarOpen {
    margin-left: 240px;
    padding-left: 9rem;
    padding-right: 9rem;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.title {
    
}

.desc {
    
}

.inviteButton {
    margin-left: auto;
    position: relative;
    display: flex;
    align-items: center;
}

.memberContainer {
    width: 100%;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.member {
    display: flex;
}

.removeButton {
    margin-left: auto;
    position: relative;
}

.member__status {
    margin-left: 10px;
}

.manageRole {
    margin-right: 1rem;
}


@media only screen and (max-width: 1024px) {
    .container {
        padding: 2rem 7rem 2rem 7rem;
    }
}

@media only screen and (max-width: 720px) {
    .container {
        padding: 1.4rem 2rem 1.4rem 2rem;
    }
}
.container {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    padding-top: 0.5rem;
}
.team {
    margin-bottom: 0.3rem;
}

.team:last-child {
    margin-bottom: 3rem;
}

.search {
    margin-bottom: 0.5rem;
}

.infiniteScroll {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-bottom: 20px;
  }
.headerSection {
  display: flex;
}

.headerSection__photoSection {
  margin-right: 5px;
}

.headerSection__photoSection img {
  margin: 0;
}

.headerSection__bodySection__memberName {
  margin-right: 5px;
}

.headerSection__bodySection__memberDesc p {
  font-size: 0.8rem;
  margin: 0;
}

.headerSection__bodySection__memberName p {
  font-weight: bold;
  margin: 0;
}
.messagesSection {
  /* min-height: 58vh; */
  height: 58vh;
  /* max-height: 58vh; */
  /* overflow-y: auto;
  overflow-x: hidden; */
  /* display: flex; */
  display: block;
  /* flex: 1; */
  /* flex-direction: column-reverse; */
  position: relative;
}

.columnReverse {
  transform: scaleY(-1);
}

.inputSection {
  border-radius: 5px;
  border: 1px solid;
  margin-top: 10px;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0;
  padding-top: 0;
}

.inputSection form {
  width: 100%;
  margin-right: 5px;
  position: relative;
}

.inputSection input {
  outline: 0;
  border: 0;
  width: 100%;
  resize: none;
}

.inputSection svg {
  cursor: pointer;
}

.emojiPicker {
  position: absolute;
  bottom: 0;
  right: 0;
}


.actionSection {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.emojiButton {
  
  margin-right: 5px;
}

/* targetting emoji pop up  */
.emojiButton > div > div {
  top: 0;
  right: 2vw;
}

@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .emojiButton > div > div {
    top: 0;
    left: 0;
    right: 0;
  }


}


.attachmentButton {
  cursor: pointer;
  border-radius: 100%;
  padding: 5px;
  background-color: #e7e7e7;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.attachmentButton:hover {
  opacity: 0.8;
}

.sendButton {
  cursor: pointer;
  border-radius: 100%;
  padding: 5px;
  background-color: aquamarine;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendButton:hover {
  opacity: 0.8;
}



/* overlay section drop zone */
.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 0; 
}

.sidebarOpen {
  width: calc(100% - (240px / var(--viewport-width)));
  margin-left: 240px;
}

.box {
  width: 100%;
  height: 100%;
  min-height: 0;
}

.backgroundOverlay {
  z-index: 9;
  background-color: white;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.contentOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 20px;
    border: 1px solid #D6D6D6;
    cursor: pointer;
    background-color: white;
    position: relative;
}

.exist {
    border: 1px solid #42E691;
}

.icon {
    margin-right: 0.4rem;
}
.icon > svg {
    width: 20px;
    color: #FF7171;
}

.container:hover {
    opacity: 0.8;
}

.iconExist {
    margin-right: 0.4rem;
}
.iconExist > svg {
    width: 20px;
    color: #42E691;
}

.removeCoupon {
    position: absolute;
    right: 0.5rem;
    top: 0.2rem;
    z-index: 2;
}

.removeCoupon > svg {
    width: 20px;
    color: #FF7171;
}
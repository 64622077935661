.container {
  padding: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.headerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .headerSection svg {
    cursor: pointer;
  }
  
  .headerSection h1 {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  
  .bodySection {
    margin-top: 5px;
    cursor: text;
    min-height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .bodySection p {
    font-size: 0.9rem;
    margin-bottom: 0;
    text-decoration: none;
  }

  
.bodySection::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.bodySection::-webkit-scrollbar-thumb {
border-radius: 20px;
background-color: #c4c4c4;
}

.bodySection::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}
  
  .menu {
    margin-bottom: 3px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  
  }
  
  .menu:hover {
    background-color: #ebecf1;
    border-radius: 5px;
  }
  
  .actionSection {
    margin-top: 20px
  }
  
  
  .icon {
    width: 15px !important;
    color: green;
    margin-left: 7px;
  }
  
  .iconUnset {
    width: 15px !important;
    margin-left: 7px;
  }

  .typingSection {
    min-height: 24px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    margin-bottom: 10px;
  }

.container {
    display: flex;
    align-items: center;
}

.buttonRead { 
    color: #42E691;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .buttonCancel { 
    color: #FF7171;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .checkBoxRead:hover {
    opacity: 0.8;
  }
  .checkBoxRead { 
    color: #42E691;
    cursor: pointer;
    margin-right: 7px;
  }
  .checkBoxUnread { 
    color: grey;
    cursor: pointer;
    margin-right: 7px;
  }
  
  .checkBoxUnread:hover {
    opacity: 0.8;
  }
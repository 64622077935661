.container {
    width: 116px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #FFE49B;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
}

.icon {
    margin-right: 5px;
}

.icon > svg {
    width: 22px;
    color: #F0B418;
}

.container:hover {
    opacity: 0.8;
}

.active {
    background: #FFE49B;
    border: 1px solid #F0B418;
}
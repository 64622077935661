.container {
    padding: 3rem;
    padding-right: 3.5rem;
    padding-left: 3.5rem;
}


@media only screen and (max-width: 1024px) {
    .container {
        padding: 1.7rem;
        padding-right: 2.3em;
        padding-left: 2.3rem;
        padding-top: 3rem;
   }
}
  
@media only screen and (max-width: 720px) {
   .container {
        padding: 1.2rem;
        padding-right: 1.8rem;
        padding-left: 1.8rem;
        padding-top: 3rem;
   }
}
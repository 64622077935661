.container {
  padding: 0.5rem;
}

.UserMenuContainer__Header {
  display: flex;
  align-items: center;
}

.UserMenuContainer__Header h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.UserMenuContainer__Header svg {
  cursor: pointer;
}

.UserMenuContainer__smallTitle {
  color: #263238;
  font-size: 0.8rem;
}

.body {
  /* display: flex; */
  margin-top: 10px;
}

.datePicker {
  /* flex-grow: 1;
  margin-right: 10px; */
}
.timePicker {
  /* flex-grow: 1; */
}

.muiPicker {
  margin-top: 0;
}

.muiKeyboardDatePicker {
  margin-top: 0;
  font-size: 0.7rem;
}


.footer {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}


.menu {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 3px;
  cursor: pointer;
}

.menu:hover {
  background-color: #ebecf1;
  border-radius: 3px;
}

.menu p {
  margin: 0;
  font-size: 0.8rem;
}

.menu img {
  margin: 0;
}

.icon {
  margin-right: 7px;
}

.label {
  margin-right: 7px;
  width: 25px;
  height: 25px;
  border-radius: 3px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text__badge {
  margin-right: 10px;
}

.text svg {
  width: 15px;
}
.sectionSmallTitle__smallTitle {
  color: #263238;
  font-size: 0.8rem;
}

.sectionSmallTitle {
  margin-top: 10px;
}

.memberSection {
  position: relative;
}

.logoutSection {
  padding: 3px;
  cursor: pointer;
}

.logoutSection:hover {
  background-color: #ebecf1;
  border-radius: 3px;
}


.iconSection {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  margin-left: 4px;
  justify-content: space-between;
}

.iconSection__item {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.iconSection__item__text {
  margin-left: 4px;
}

.iconSection__item__margin {
  margin-right: 10px;
}

.profileImage {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  object-fit: cover;
}

.profileImage:hover {
  opacity: 0.8;
}

.iconLogout {
  position: relative;
}

.iconLogoutDisableClick {
  position: relative;
  cursor: default;
}

.logoutLoadingOverlay {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: white;
  opacity: 0.7;
  top: 0px;
  left: 0px;
}

.logoutLoadingSpinner {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
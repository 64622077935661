/* Comments Section */

.CommentsSectionContainer__mediumTitle {
  color: #263238;
  font-size: 1rem;
  font-weight: 500;
}

.CommentsSectionContainer__commentsSection {
  display: inline-flex;
  margin-bottom: 15px;
}

.CommentsSectionContainer__commentTextContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 6px;
  background-color: #ebecf1;
  border-radius: 4px;
  display: inline-flex;
  box-shadow: 0 2px 5px #ccc;
}

.CommentsSectionContainer__commentActionContainer {
  display: inline-flex;
}

.CommentsSectionContainer__commentActionContainer > p {
  text-decoration: underline;
  flex: 2 1 auto;
  margin-left: 5px;
  cursor: pointer;
  color: grey;
}

.CommentsSectionContainer__commentActionContainer > svg {
  flex: 2 1 auto;
  margin-right: 5px;
  cursor: pointer;
  color: grey;
}

.CommentsSectionContainer__commentHeaderContainer {
  display: inline-flex;
}

.CommentsSectionContainer__commentUser {
  font-weight: bold;
  margin-right: 8px;
}

.CommentsSectionContainer__commentDate {
  color: grey;
  margin-right: 5px;
}


.commentTitle {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AttachmentItem {
    display: block;
    margin-bottom: 5px;
  }

  .AttachmentItem p,
  .AttachmentItem i {
    margin: 0;
    font-size: 0.6rem;
  }
  
  .mainContainer {
    display: flex;
    justify-content: flex-end;
    
  }
  .mainContainerNotSelf {
    display: flex;
   
    
  }
  
  .mainSection {
    display: flex;
  }
  
  .photoSection {
    display: inline-block;
    margin-right: 5px;
  }
  
  .photoSection img {
    margin: 0;
  }
  
  .bodySection {
    display: inline-block;
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    max-width: 70%;
  }
  
  
  .contentAttachmentItem {
    display: block;
  }
  
  .headerAttachmentItem {
    display: flex;
    align-items: center;

  }
  
  .senderName {
    margin-right: 5px;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .senderName p {
    font-size: 0.6rem;
    font-weight: bold;
  }
  
  .sentDate {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  
  .main {
    padding: 4px 15px 0 15px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .main__icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .main__title {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  
  .main__title h1 {
    font-size: 0.6rem;
    overflow-wrap: break-word;
  }
  .main__title a {
    font-size: 0.6rem;
    text-decoration: underline;
    color: blue;
  }
  
  .main__content p {
    overflow-wrap: break-word;
    font-size: 0.6rem;
  }
  
  .main__icon img {
    max-width: 80px;
    max-height: 40px;
    cursor: pointer;
    margin-bottom: 0;
  }
  
  @media only screen and (max-width: 600px) {
    .bodySection {
      max-width: 80%;
    }
  
    .main__icon img {
      max-width: 300px;
    }
  }
  
  
  .playIcon > svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 8px;
    left: 24px;
    color: white;
  }
.container {
    height: 60px;
    align-items: center;
    display: flex;
    background-color: white;
}

.containerAll {
    height: 60px;
    align-items: center;
    display: flex;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.disableScrollbar::-webkit-scrollbar {
    display: none !important;
}
  
.disableScrollbar {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}
  
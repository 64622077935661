.container {
    display: flex;
}

/* .photo {
    margin-right: 10px;
}

.photo > div {
    margin: 0 !important;
}

.photo > div > img {
    width: 38px !important; 
    height: 38px !important;
} */

.text {
    /* height: 100%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.typingSection {
    margin-top: -3px;
}
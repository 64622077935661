.container {
    /* max-height: 50vh;
    overflow-y: auto; */
  }

  .titleAndAction {
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
  }

  .footerChecklist {
    width: 100%;
    height: 20px;
  }


.titleAction {
  display: flex;
  align-items: center;
  position: relative;
}

.iconAction > svg {
  color: black;
  width: 18px;
}

.iconAction {
  cursor: pointer;
  margin-left: 5px;
  height: 22px;
  width: 22px;
  background-color: #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.iconAction:hover {
  opacity: 0.8;
}
.InvitePage {
  height: 75vh;
  display: grid;
  place-items: center;
}

.InvitePage__container {
  padding: 100px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px #ccc;
}

.InvitePage__textSection > svg {
  margin-bottom: 5px;
  font-size: 40px;
}

.InvitePage__textSection__successIcon {
  color: #28a745;
}

.InvitePage__textSection__errorIcon {
  color: red;
}

.InvitePage__textSection > p {
  font-size: 0.8rem;
}

.separatorLine {
  margin-top: 20px;
  margin-bottom: 20px;
}
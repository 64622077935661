.container {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 0.5rem;
}

.icon {
    margin-left: 5px;
}

.icon > svg {
    width: 18px;
    color: #7a7a7a;
}

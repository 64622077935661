.list {
    height: 83vh;
    width: 240px;
    background-color: white;
    /* border-bottom: 1px solid #D6D6D6; */
    border: 1px solid #ECECEC;
    /* border-radius: 8px 8px 0px 0px; */
    padding: 1rem;
    max-height: 83vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.list::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.list::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

@media only screen and (max-width: 1200px) {
    .list {
       height: 84vh;
       max-height: 84vh;
    }
}

@media only screen and (max-width: 720px) {
    .list {
        width: 91vw;
    }
}
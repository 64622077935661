.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

.headerSection svg {
  cursor: pointer;
}

.headerSection h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.bodySection {
  margin-top: 5px;
}

.bodySection p {
  font-size: 0.9rem;
  margin-bottom: 0;
  text-decoration: none;
}

.menu {
  margin-bottom: 3px;
  padding: 5px;
  cursor: pointer;
}

.menu:hover {
  background-color: #ebecf1;
  border-radius: 5px;
}

.actionSection {
  margin-top: 20px
}

.description {
  margin-bottom: 15px !important;
}

.green {
  background-color: #28a745;
  border-radius: 5px;
}

.green > p {
  color: white !important;
}

.green:hover {
  background-color: aquamarine;
}
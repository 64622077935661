.container {
    display: flex;
    align-items: center;
    position: relative;
}

.bottomLine {
    border-bottom: 2px solid black;
    position: absolute;
    width: 100%;
    bottom: -5px;
    left: 0px;
}

.icon {
    margin-right: 0.2rem;
}

.icon > svg {
    width: 18px;
}

.menu {
    margin-left: 0.3rem;
}
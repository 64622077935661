.container {
    padding: 3rem;
}

.title {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.search {
    margin-bottom: 1rem;
}

.list {
    max-height: 350px;
    overflow-y: auto;
    height: 350px;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
}

.list::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.list::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}


@media only screen and (max-width: 1024px) {
    .container {
        padding: 1.5rem;
        padding-top: 3rem;
    }
  }
  
  @media only screen and (max-width: 720px) {
    .container {
        padding: 1rem;
        padding-top: 3rem;
    }
  }
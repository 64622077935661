.container {
  background-color: lightgrey;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
}

.container:hover {
  background-color: grey;
}

.medium {
  width: 30px;
  height: 30px;
}

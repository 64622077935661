.container {
    width: 280px;
    padding: 1rem;
    min-height: 180px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    position: relative;
}

.badgeSection {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.category {
    margin-right: 0.5rem;
}

.infoTitle {
    margin-bottom: 1rem;
}

.cta {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

.cta > svg {
    width: 20px;
    color: #FDC532;
}

.titleSection {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 0.5rem;
}

.icon > svg {
    width: 22px;
    color: #B5B5B5;
}
.container {
    background-color: #f7f7f7;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 140px; */
    min-height: 110px;
}

.title {
    display: flex;
    align-items: center;
}

.icon {
    margin-left: 0.3rem;
}

.icon > svg {
    width: 32px;
    height: 32px;
}

.iconGood {
    color: #42E691;
}

.iconBad {
    color: #FF7171;
}

.iconNormal {
    color: #1F3762;
}
.container {
    /* padding: 2px; */
    display: flex;
}

.container > svg {
    width: 22px;
    color: #708FC7;
}

.container > svg:hover {
    opacity: 0.8;
}


/* Using ImageMember component */
.container > div > span > img {
    width: 25px !important;
    height: 25px !important;
}

.container > div {
    margin: 0 !important;
}
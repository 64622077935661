
.forLoading {
    position: fixed;
    top: 106px;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }
  
  .forLoading__box {
    background-color: white;
    opacity: 0.8;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 106px;
    left: 0;
    z-index: 3;
  }
  
  .forLoading__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 4;
    margin-bottom: 20vh;
  }
.container {
    height: 100vh;
    width: 100%;
    background-image: url(../../assets/WelcomePage/company-illustration.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    margin-bottom: 1rem;
}

.text {
    margin-bottom: 3rem;
    padding-left: 20rem;
    padding-right: 20rem;
}

.formSection {
    width: 40%;
}

.form {
    margin-bottom: 15px;
}

.formText {
    margin-bottom: 10px;
}

.button {
    display: flex;
    justify-content: end;
}

@media only screen and (max-width: 1024px) {
    .formSection {
      width: 60%;
    }

    .text {
        padding-left: 10rem;
        padding-right: 10rem;
    }
  }
  
  @media only screen and (max-width: 720px) {
    .container {
        background-size: cover;
    }

    .formSection {
      width: 70%;
    }

    .text {
        padding-left: 5rem;
        padding-right: 5rem;
    }
  }
.container {
    position: fixed;
    bottom: 0;
    right: 20px;
    max-height: 250px;
    background-color: white;
    width: 350px;
    z-index: 5000 !important;
    border: 1px solid #c4c4c4;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.header {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.body {
    padding: 0.5rem;
    overflow-y: auto;
    max-height: 185px;
}

.body::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Handle */
  .body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
  }
  
  .body::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
  }

  .iconClose {
    cursor: pointer;
  }
.container {
    padding: 2rem;
    padding-top: 3rem;
}

.form {
    margin-bottom: 20px;
}

.formText {
    margin-bottom: 10px;
}

.title {
    margin-bottom: 1rem;
}

.description {
    margin-bottom: 1rem;
}

.button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
.container {
    display: flex;
    align-items: center;
}

.text {
    margin-right: 1rem;
}

.button {
    border-radius: 100%;
    border: 1px solid #B5B5B5;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.button > svg {
    width: 18px;
    color: #FF7171;
}

.button:hover {
    opacity: 0.8;
}


.checklistAndTitle {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .checklist {
    margin-right: 0.5rem;
    cursor: pointer;
  }

  .disabledDate {
    margin-bottom: 0.6rem;
    margin-top: 0.5rem;
  }
.container {
  padding: 2rem 2.5rem;
}

.eventsSection {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 25px;
}

.eventsSection__title {
  margin-bottom: 20px;
}

.eventsSection__schedule {
  margin-bottom: 20px;
}

.eventsSection__desc {
  position: relative;
}



.eventsSection__subscribers {
  margin-bottom: 20px;
  position: relative;
}

.subscribersHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}

.subscribersHeader h1 {
  margin: 0;
  margin-right: 10px;
  font-size: 1rem;
}

/* .subscribersHeader p {
  margin: 0;
  margin-right: 10px;
  font-size: 0.8rem;
} */

.subscribersItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subscribersItem span {
  margin-right: 5px;
  font-size: 0.8rem;
}


.plusButton {
  background-color: lightgrey;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  /* margin-top: 5px; */
  margin-left: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plusButton:hover {
  opacity: 0.8;
}

.actionSection {
  position: relative;
}

.actionSection > button {
  margin-right: 5px;  
}


.schedule__start {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}


.schedule__end {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}


.schedule__repeat {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.schedule__titleMenu {
  width: 65px;
}

.schedule__titleMenu > p {
  font-weight: bold;
}


.selectRepeatUntilSection {
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .schedule__start {
    display: block;
  }
  
  
  .schedule__end {
    display: block;
  }
  
  
  .schedule__repeat {
    display: block;
  }
}

.dueDate {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.datePicker {
  margin-right: 10px;
  margin-bottom: 5px;
}

.manageSubscribers {
  /* margin-top: 5px; */
  margin-bottom: 25px;
}
.container {
    padding-left: 1.1rem;
    padding-right: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navIconSection {
    display: flex;
    align-items: center;
    position: relative; 
  }
.BoxPopOver {
  background: #FFF;
  border: 1px solid #DEEAFF;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  width: 260px;
  margin: 16px;
  display: inline-block;
  text-align: left;
  z-index: 1000;
  position: absolute;
  top: 15px;
  right: 0;
}

.BoxPopOver__left {
  left: 0;
  right: auto;
}

.BoxPopOver__big {
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .BoxPopOver__big {
    width: 80vw;
  }
}
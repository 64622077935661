.ErrorButton {
    background: #fdc332;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
    width: 80%;
}

.ErrorButton:hover {
    background: #ffde8b;
}

.ErrorButton > p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: #1F3762;
}

.ErrorButton > img {
    width: 25px;
}
.MainLayout {
  text-align: left;
  margin-bottom: 1%;
  padding-top: 1vh;
}

@media only screen and (max-width: 600px) {
  /* targetting emoji pop up  */
  .mainCol {
    padding-left: 0;
    padding-right: 0;
  }
}
.breadCrumbSection {
    display: flex;
    align-items: center;
    margin-top: 1px;
}

.breadCrumb > ol > li > svg {
    color: #b5b5b5;
    width: 14px;
}

.breadCrumb > ol {
    flex-wrap: nowrap;
}

/* [class=MuiBreadcrumbs-separator] {

} */

.breadcrumbItem {
    display: flex;
    align-items: center;
    color: #262727;
    font-family: Nunito;
    font-size: 14px;
    line-height: 0;
    font-weight: 400;
    min-height: 24px;
    white-space: nowrap;
}

.breadcrumbItem > svg {
    fill: #262727;
    margin: 0;
    margin-right: 3px;
    width: 20px;
}

.breadcrumbItem:hover { 
    opacity: 0.8;
    text-decoration: none;
    color: #262727;
}

.separateRoute {
    cursor: default;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.separateRoute > span {
    color: #262727;
    font-family: Nunito;
    font-size: 14px;
    line-height: 0;
    white-space: nowrap;
}

.separateRoute > svg {
    fill: #262727;
    margin: 0;
    margin-right: 3px;
    width: 20px;
}

@media only screen and (max-width: 720px) {
    .separateRoute > span {
        font-size: 10px;
    }

    .breadcrumbItem {
        font-size: 10px;
        min-height: 24px;
    }

    .breadCrumb > ol > li > svg {
        width: 10px;
    }

    .breadcrumbItem > svg {
        width: 16px;
        margin-right: 3px;
    }

    .separateRoute > svg {
        width: 16px;
        margin-right: 3px;
    }
}
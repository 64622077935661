.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.wrapper {
    background-color: #ffffff;
    border-radius: 100%;
    border: 1px solid #d6d6d6;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 1px 4px #ccc;
  }

  .wrapper p {
    font-size: 0.6rem;
    margin: 0;
    
  }

  .wrapper > svg {
      width: 10px;
   
  }

  .wrapperXs {
    background-color: #ffffff;
    border-radius: 100%;
    border: 1px solid #d6d6d6;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 1px 4px #ccc;
  }

  .wrapperXs p {
    font-size: 0.5rem;
    margin: 0;
    
  }

  .wrapperXs > svg {
      width: 8px;
   
  }

  .plusButton {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  
  .plusButton:hover {
    opacity: 0.8;
  }

  .plusButton > svg {
    width: 16px;
    color: #ffffff;
  }

  .additionalPlusButton {
    margin-right: 8px;
  }

  .primary {
    background-color: #7A7A7A;
  }

  .secondary {
    background-color: #708FC7;
  }
.input {
  width: 100%;
  margin-right: 5px;
  position: relative;
  outline: 0;
  border: 0;
  resize: none;
  padding: 2px;
  font-size: 11px;
  font-family: Nunito;
}

.input::placeholder {
  color: #D6D6D6;
}

.input::-webkit-input-placeholder {
  color: #D6D6D6;
}
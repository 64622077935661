.container {
    cursor: pointer;
}

.container:hover {
    opacity: 0.8;
}

.container > svg {
    width: 20px;
}
.mainContainer {
  overflow-y: auto;
  max-height: 90vh;
}
.CardDetailContainer__modalHeader {
  background-color: white;
  padding: 1rem;
  padding-bottom: 0px;
}

.titleSection {
  width: 90%
}

.titleSection h1 {
  overflow-wrap: break-word;
  word-wrap: break-all;
  white-space:normal;
}

.archivedSection {
  display: flex;
  background-color: #fafcc2;
  align-items: center;
  padding: 6px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.archivedSection svg {
  margin-right: 6px;
}

.archivedSection h1 {
  margin: 0;
}

.CardDetailContainer__modalBody {
  background-color: white;
  min-height: 60vh;
  padding: 1rem;
  padding-top: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.CardDetailContainer__imageMember {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.CardDetailContainer__mainColumn {
  padding-left: 0;
  padding-right: 12px;
  width: 85%;
}

.CardDetailContainer__sideColumn {
  padding-left: 0;
  padding-right: 0;
  width: 15%;
}

.CardDetailContainer__smallSectionContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

.CardDetailContainer__mediumSectionContainer {
  margin-top: 10px;
  margin-bottom: 20px;
}

p {
  margin-bottom: 5px;
}

.CardDetailContainer__smallTitle {
  color: #263238;
  font-size: 0.7rem;
}

.CardDetailContainer__mediumTitle {
  color: #263238;
  font-size: 1rem;
  font-weight: 500;
}

.CardDetailContainer__label {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.CardDetailContainer__dueDateContainer {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 6px;
  background-color: #ebecf1;
  border-radius: 4px;
  display: inline-flex;
  cursor: pointer;
}

.CardDetailContainer__dueDateContainer > p{
  margin-bottom: 0;
}


/* Comments Section */
.CardDetailContainer__commentsSection {
  display: inline-flex;
  margin-bottom: 15px;
}

.CardDetailContainer__commentTextContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 6px;
  background-color: #ebecf1;
  border-radius: 4px;
  display: inline-flex;
  box-shadow: 0 2px 5px #ccc;
}

.CardDetailContainer__commentActionContainer {
  display: inline-flex;
}

.CardDetailContainer__commentActionContainer > p {
  text-decoration: underline;
  flex: 2 1 auto;
  margin-left: 5px;
  cursor: pointer;
  color: grey;
}

.CardDetailContainer__commentActionContainer > svg {
  flex: 2 1 auto;
  margin-right: 5px;
  cursor: pointer;
  color: grey;
}

.CardDetailContainer__commentHeaderContainer {
  display: inline-flex;
}

.CardDetailContainer__commentUser {
  font-weight: bold;
  margin-right: 8px;
}

.CardDetailContainer__commentDate {
  color: grey;
  margin-right: 5px;
}

/* Add comment section */

.CardDetailContainer__AddCommentContainer {
  display: flex;
  margin-bottom: 20px;
}

.CardDetailContainer__inputComment {
  width: 100%;
}

.buttonAction {
  margin-bottom: 5px;
  position: relative;
}

/* overlay section drop zone */
.container {
  position: relative;
}
.box {
  width: 100%;
  height: 100%;
}

.backgroundOverlay {
  z-index: 9;
  background-color: white;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.contentOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.createdSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110%;
}

.createdSection > p {
  font-size: 0.8rem;
  margin-bottom: -5px;
}

.createdSection__photoNameDate {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.createdSection__photoNameDate__creatorAndDate > p {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: -3px;
}

.createdSection__photoNameDate__notFound {
  display: flex;
  align-items: center;
}

.createdSection__photoNameDate__notFound > p {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-right: 4px;
}

.listAndTeamSection {
  position: relative;
}

.listAndTeamSection > p {
  font-size: 0.8rem;
}

.listAndTeamSection__listName {
  cursor: pointer;
}

.listAndTeamSection > p > a {
  text-decoration: underline;
}

.cheersSection {
  margin-top: 15px;
  margin-bottom: 15px;
}

.seenSection {
  margin-bottom: 5px;
  padding-left: 5px;
}

.infiniteScroll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}

.typingSection {
  min-height: 24px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
  .CardDetailContainer__modalBody {
    display: block;
  }

  .CardDetailContainer__mainColumn {
    width: 100%;
  }
  
  .CardDetailContainer__sideColumn {
    width: 100%;
  }
}
.container {
    height: 82vh;
    padding: 1rem;
}

.sidebarOpen {
  margin-left: 240px;
}

.activeFilterIcon > svg {
    margin-left: 4px;
    color: #42E691;
    width: 18px;
  }
  
  @media only screen and (max-width: 720px) {
    .activeFilterIcon > svg {
        margin-right: 2px;
      }
  }
.container {
    width: 100%;
}

.body {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 1rem;
    height: 100%;
    width: 100%;
}

.statText {
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.skeleton {
    margin-top: 0.5rem;
}
.Doc {
  margin-bottom: 15px;
  margin-right: 10px;
  cursor: pointer;
  padding: 15px 20px 15px 20px;
  width: 200px;
  height: 250px;
  border: 1px solid #a6a6a4;
  border-radius: 8px;
  overflow: hidden;
}

.main {
  max-width: 100%;
  max-height: 100%;
}

.main__title h1 {
  font-size: 1.1rem;
  overflow-wrap: break-word;
}

.main__content p {
  overflow-wrap: break-word;
}



.outerContainer {
    display: block;
    margin-bottom: 0;
    cursor: pointer;
    padding: 0;
  }
  
  .mainContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .photoContentSection {
    display: flex;
    align-items: flex-start;
  }
  
  .photoSection {
    margin-right: 4px;
  }
  
  .photoSection img {
    margin: 0;
  }

  .contentSection {
      margin-right: 15px;
  }

  .contentSection__content {
      text-align: left;
  }
  
  .contentSection__header {
    display: flex;
    align-items: center;
  }
  
  .contentSection__header__title {
    margin-right: 5px;
    text-align: left;
  }
  
  .contentSection__header__title h1 {
    margin: 0;
    font-size: 0.8rem;
  }
  
  .contentSection__header__title svg {
    font-size: 0.7rem;
  }

  .contentSection__content__creator {
    color: #0f4c75;
  }

  .contentSection__content p {
      font-size: 0.7rem;
      word-break: break-all;
  }

  .bodySection__counter span {
    font-size: 0.6rem;
  }
  
  .contentSection svg {
    margin: 0px;
    width: 15px;
  }

  .bodySection__counter {
    margin-right: 10px;
  }
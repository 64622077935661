.Folder {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: center;
}

.Folder h1 {
  margin-bottom: 0;
}

.Folder img {
  margin-bottom: 0;
}
.container {
    display: flex;
    align-items: flex-start;
    min-height: 80px;
    padding: 0.4rem;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.container:hover {
    background-color: #f7f7f7;
}

.disableClick {
    cursor: default;
}

.disableClick:hover {
    background-color: white;
}

.photo {
    margin-right: 0.3rem;
}

.item {
    margin-bottom: 0.5rem;
}

.text {
    width: 100%;
    max-width: 89%;
    border-radius: 5px;
    padding: 0.2rem;
    border: 1px solid #ececec;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.fullWidth {
    max-width: 100%;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
}

/* .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.content__text {
    margin-right: 0.5rem;
}

.content__text:last-child {
    margin-right: 0px;
} */

.image {
    position: relative;
}

.icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.icon > svg {
    width: 12px;
    color: yellow;
}


.blur::before {
    position: absolute;
    left:0; top:0; bottom:0; right:0;
    z-index: 1;
    background-color: white;
    border-radius: 5px;
    opacity: 0.8;
    content: "";
    /* background: inherit; */
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -ms-filter: blur(5px);
    -o-filter: blur(5px);
    filter: blur(5px);
    width: 100%;
    height: 100%;
  }
  
  .blurText {
    position: absolute;
    left:0; top:-12px; bottom:0; right:0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blurIconPrivate {
    margin-right: 0.5rem;
  }
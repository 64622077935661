.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    height: 20px;
}

.text {
   margin-right: 6px; 
}

.icon > svg {
    width: 14px;
}

.realtime {
    background-color: #EFF5FF;
}

.recapMonthWeekly {
    background-color: #0C2044;
}

.iconRealtime {
    color: #0C2044;
}

.iconRecapMonthWeekly {
    color: #F0B418;
}
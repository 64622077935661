.ColorBox {
  min-height: 30px;
  min-width: 42px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ColorBox:hover {
  opacity: 0.8;
}

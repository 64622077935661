.wrapper {
    position: relative;
}
.container {
    height: 40px;
    width: 140px;
    border-radius: 5px;
    border: 1px solid #B5B5B5;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
}

.arrow > svg {
    color: #B5B5B5;
}

.differentRole {
    border: 1px solid #708FC7; 
}

.differentRoleIcon > svg {
    color: #FDC532;
}

.disabled {
    border: none;
    cursor: default;
}

.text {
    width: 100%;
}
.container {
    width: 100px;
    height: 50px;
    background-color: beige;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}